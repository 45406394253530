<div class="rows result-bloc">
    <div class="cols more-detail">
        <div class="metrics">
            <div class="header">
                <span class="title">Tout savoir sur le vote</span>
                <div id="activeBorder" class="active-border">
                  <circle-progress
                    [titleFontSize]="'12'"
                    [titleFontWeight]="'600'"
                    [percent]="percentVote"
                    [radius]="25"
                    [outerStrokeWidth]="5"
                    [backgroundPadding]="-5"
                    [showSubtitle]="false"
                    [innerStrokeWidth]="0"
                    [outerStrokeColor]="'#00E0AB'"
                    [innerStrokeColor]="'#C7E596'"
                    [backgroundColor]="'#F1F1F1'"
                    [animation]="true"
                    [animationDuration]="300">
                  </circle-progress>
              </div>
            </div>
            <div class="metric-body">
              <div class="progressbar-container">              
                <div class="progress-cont" *ngFor="let bar of proposalBars">
                  <div class="metric" [style.width]="(bar.percent<=15)? '100%': bar.percent+'%'">
                    <span class="proposal-name">{{bar.proposal}}</span>
                    <span class="proposal-percent">{{bar.percent}}%</span>
                  </div>
                  <div class="prog-bar" role="progressbar" [style.width]="bar.percent+'%'" aria-valuemin="0" aria-valuemax="100"></div>
                </div>                             
              </div>
              <div class="num-metrics-bloc" *ngIf="voteMetrics">
                <!--<div class="col">-->
                  <div class="metric_nb msg">
                    <p class="metric-def">Messages</p>
                    <p class="metric-val">{{voteMetrics.metricCount.messages}}</p>
                  </div>
               <!-- </div>-->
                <!--<div class="col">-->
                  <div class="metric_nb cmt">
                    <p class="metric-def">Commentaires</p>
                    <p class="metric-val">{{voteMetrics.metricCount.comments}}</p>
                <!--  </div>  -->
                </div>
              </div>
            </div>
        </div>
    </div>
    <div class="cols list-voter">
        <div class="voter-list">
            <div class="header">
                <span class="title">Les votants</span>
            </div>
            <div id="carouselExample" class="carousel slide">
                <div class="navigation" *ngIf="voterCount>12">
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                    <i class="bi bi-chevron-left" aria-hidden="true"></i>
                    <span class="visually-hidden">Previous</span>
                  </button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item" *ngFor="let voter of voters | keyvalue" [ngClass]="{'active': isFirst}">
                    <div class="voters-container">
                      <div class="col-md-2e cont-voter" *ngFor="let v of show(voter.value)">
                        <div class="img-prof">
                          <img src="assets/images/avatar.png" *ngIf="v.user.picture">
                          <span  class="default-pic" *ngIf="!v.user.picture" [style.background]="v.user.color">{{getAb(v.user.first_name, v.user.last_name)}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="navigation" *ngIf="voterCount>12">
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                    <i class="bi bi-chevron-right" aria-hidden="true"></i>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
        </div>
    </div>

     <!-- You can now use it in app.component.html -->

</div>
