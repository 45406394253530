import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthUser, ElectionI, TopicI } from '../models';
import { AuthService } from '../services/auth.service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-topic-info',
  templateUrl: './topic-info.component.html',
  styleUrls: ['./topic-info.component.scss']
})
export class TopicInfoComponent implements OnChanges{
  @Input() topic: TopicI;  
  @Input() election: ElectionI;

  remainingDays : any;
  nbr_votant : any;
  currentUser: AuthUser;
  isScroll: boolean = false;
  resultDisplay : boolean = false;
  constructor(private auth: AuthService,
    private translate: TranslateService){}
  
  ngOnChanges() {
    if(this.election && this.election.startDate && this.election.endDate){
      if(new Date() >=  new Date(this.election.startDate) && new Date() < new Date(this.election.endDate)){
        this.remainingDays = this.auth.calculateDayDifference(new Date().toISOString(), this.election.endDate);
        this.nbr_votant = 9;
		this.auth.apiCall('vote/voters_count/'+this.election._id,'get',{}, true).subscribe(        
		result => {
			console.log(result, "votant");
			this.nbr_votant = result;
		},
		error => {          
			console.log('Error',error)
			this.nbr_votant = error.res;
		});
      }
      if(new Date() > new Date(this.election.endDate)){
        this.resultDisplay = true;
      }      
    }    
    let vm = this;
    setTimeout(()=>vm.checkScrollInDescription(), 400);
  }
  
  ngOnInit():void{
    console.log("testtttttttte on inittt");
    if(this.auth.currentUser()){
      this.currentUser = this.auth.currentUser();
    }else{
      this.getCurrentUser();
    }
  }

  checkScrollInDescription(){
    const content = $('#desc-content') as any;
    const bloc = $('#desc-bloc') as any;
    const percent = (this.resultDisplay) ? 50 : 90;
    //bloc.height(this.getPercentValue(content.height(), percent));
    const heightVal = this.getPercentValue(content.height(), percent)+'px';
    if(this.resultDisplay){
      bloc.css({ "max-height":  heightVal});
    }else{
      bloc.css({ "height":  heightVal});
    }
    const first = bloc.get(0) as any;
    if((bloc && first) && (first.scrollHeight >= 0 && bloc.height()>=0)){
      this.isScroll = (bloc.get(0).scrollHeight - bloc.height())>15;
    }
  }

  getPercentValue(baseValue: number, percent: number): string{
    // console.log("je test", percent, baseValue, ((percent*baseValue)/100).toFixed(2));
    return ((percent*baseValue)/100).toFixed(2);
  }

  getCurrentUser(){
    let vm=this;
    this.auth.apiCall('user/current-user','get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          this.auth.setCurrentUser(res);
          this.currentUser = res
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }
}
