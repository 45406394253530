<div [ngClass]="{'to-hide': toHide && !isExpanded}">
  <div class="sidebar" [ngClass]="[ isExpanded ? 'expanded' : 'adjust' ]">
    <div class="header" (click)="handleSidebarToggle(true)">  
      <img src="assets/images/nico-logo.png" [routerLink]="['/dashboard']"> 
    </div>
    <div class="content" id="step1">
      <div class="link" title="Dashboard">
        <div class="content" [routerLink]="['/dashboard']" [routerLinkActive]="'active'" (click)="handleSidebarToggle(true)">    
          <div class="icon">
            <span class="material-symbols-outlined"> dashboard</span>
          </div>      
          <div class="title">{{"dashboard" | translate}}</div>
        </div>
      </div>
      <div class="link" title="Forum" id="step4">
        <div class="content" [routerLink]="['/forum']" [routerLinkActive]="'active'" (click)="handleSidebarToggle(true)">          
          <div class="icon">
            <span class="material-symbols-outlined">sms</span>
          </div>
          <div class="title">{{"forum" | translate}}</div>
        </div>
      </div>
      <div class="link" title="Mes sujets"  (click)="modal = 0" data-bs-toggle="modal" data-bs-target="#infoProductModal">
        <div class="content" [routerLinkActive]="'active'" (click)="handleSidebarToggle(true)">          
          <div class="icon">
            <span class="material-symbols-outlined not-ready">bookmark</span>
          </div>
          <div class="title not-ready">
            {{"myTopics" | translate}}</div>
        </div>
      </div>
      <div class="link" title="Zone de suivi"  (click)="modal = 1" data-bs-toggle="modal" data-bs-target="#infoProductModal">
        <div class="content" [routerLinkActive]="'active'" (click)="handleSidebarToggle(true)">          
          <div class="icon">
            <span class="material-symbols-outlined not-ready">donut_large</span>
          </div>
          <div class="title not-ready">
            {{"trackingArea" | translate}}
          </div>
        </div>
      </div>
    </div>
    <div class="footer" id="step2">
      <!--<span class="material-symbols-outlined">military_tech</span>
      <span class="material-symbols-outlined">editor_choice</span>
      <span class="material-symbols-outlined">bar_chart_4_bars</span>-->
      <div class="pic-profile" *ngIf="currentUser">
        <img src="assets/images/avatar.png" *ngIf="currentUser.picture">
        <span  class="default-pic" *ngIf="!currentUser.picture" [style.background]="currentUser.color">{{getAb(currentUser.first_name, currentUser.last_name)}}</span>
      </div> 
      <div class="role" *ngIf="currentUser">
        {{currentUser.role}}
      </div> 
      <!--<div class="leve">
        <span class="material-symbols-outlined">military_tech</span>
        <span class="footer-txt"> Level 1</span>
      </div> 
      <div class="contrub">
        <span class="material-symbols-outlined">bar_chart_4_bars</span>
        <span class="footer-txt"> 20 Contributions</span>
      </div>-->
      <div class="toggle" (click)="handleSidebarToggle()">
        <span class="material-symbols-outlined"> {{isExpanded ? 'west' : 'east'}}</span>
      </div>
    </div>
  </div>
</div>

  <!-- Modal vote for product vote  -->
<div class="modal fade delete-update-modal" id="infoProductModal" tabindex="-1" aria-labelledby="exampleModalLabela" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header-mod">
        <p *ngIf="modal" class="modal-title" id="exampleModalLabela">{{"followZoneTitle" | translate}}</p>
        <p *ngIf="!modal" class="modal-title" id="exampleModalLabela">{{ "MyTopicsTitle" | translate}}</p>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body-mod">
          <div class="msg-info">
              <span *ngIf="modal">{{"followZoneTxt" | translate}}</span>
              
              <span *ngIf="!modal">{{"MyTopicsTxt" | translate}}</span>
          </div>
      </div>
      <div class="modal-footer-mod">
      </div>
    </div>
  </div>
</div>