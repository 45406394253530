<div class="nico-centent">
    <div class="head-bloc">
        <p class="title"><span class="frm" [routerLink]="['/forum']">Forum <i class="bi bi-chevron-right"></i> </span><span class="crtf">  {{"createNewTopic" | translate}}</span></p>
        <p class="topic-news">{{"fillForm" | translate}}</p>
    </div>
    <div class="body-content">
        <p class="form">{{"form" | translate}}</p>
        <div class="input-group">
            <span class="error-input">{{erros.title}}</span>
            <input type="text" placeholder="{{'topicTitle' | translate}}" [(ngModel)]="title" [disabled]="isToEdit">
        </div>
        <div class="input-group">
            <span class="error-input">{{erros.category}}</span>
            <select name="category" [(ngModel)]="category" [disabled]="isToEdit">
                <option value="">{{"categorieChoice" | translate}}</option>
                <option *ngFor="let categorie of categories" value="{{categorie._id}}">{{categorie.name}}</option>
            </select>
        </div>
        <div class="input-group">
            <span class="error-input">{{erros.description}}</span>
            <textarea name="description" id="" cols="30" rows="10" [(ngModel)]="description" placeholder="{{'topicDesc1' | translate}}&#10;{{'topicDesc2' | translate}}"></textarea>
        </div>
        <div class="new-topic-bloc">
            <button class="new-topic" (click)="sendTopic()">{{"save" | translate}}  <span class="material-symbols-outlined">arrow_forward_ios</span></button>
        </div>
    </div>
</div>