import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ElectionI, ProposalBar, TopicI, VoteMetrics } from '../models';

@Component({
  selector: 'app-vote-result',
  templateUrl: './vote-result.component.html',
  styleUrls: ['./vote-result.component.scss']
})
export class VoteResultComponent implements OnChanges {
  
  @Input() topic: TopicI;
  @Input() election: ElectionI;

  constructor(private auth: AuthService){}

  voters: any = {};
  isFirst: boolean =  false;
  voteMetrics : VoteMetrics;
  proposalBars: ProposalBar[]= [];
  percentVote : number;
  voterCount: number;

  getAb(firstN: string, lastN: string):string{
    // if(!this.isFirst){
    //   this.isFirst = true;
    // }
    return firstN[0]+lastN[0]
  }

  show(data: any): any[]{
    return data
  }

  ngAfterViewInit() {
    this.isFirst = true;
  }

  getProposalCount(props: any){
    if(this.election && this.election.proposals){
      for(let i in this.election.proposals){
        if(props['prop_'+i]){
          this.proposalBars.push({proposal: this.election.proposals[i], percent: this.calculatePercent(props['prop_'+i])})
        }else{
          this.proposalBars.push({proposal: this.election.proposals[i], percent: 0})
        }
      }
    }
  }

  calculatePercent(val: number){
    return Math.trunc((val/this.voterCount)*100);
  }
  getVoterList(id: string){      
    let vm=this;
    if(id){
      this.auth.apiCall('vote/voter-list/'+id,'get',{}, true).subscribe(        
        result => {
          let res = result as any;
          if(res){
            vm.voterCount = res.length
            // for(let j=0;j<24;j++){
            //   res.push(res[0]);
            // }
            let page = 0;
            for(let i=0;i<res.length;i++){
              if(i % 12 === 0 || i === 0){
                page++;
                this.voters['i_'+page] = [];
              }
              this.voters['i_'+page].push(res[i]);
            }      
          }
        },
        error => {          
          console.log(error)
        });
    }
  }

  getVoteMetrics(id: string){      
    let vm=this;
    if(id){
      this.auth.apiCall('vote/vote-metrics/'+id,'get',{}, true).subscribe(        
        result => {
          let res = result as any;
          if(res.metricCount){
            this.voteMetrics = res;
            this.percentVote = Math.trunc(this.voteMetrics.participantsPercent);
            this.getProposalCount(this.voteMetrics.proposals);
          }
        },
        error => {          
          console.log(error)
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.election && (new Date() > new Date(this.election.endDate))){
      console.log("election ended!")
      this.getVoterList(this.election._id);   
      this.getVoteMetrics(this.election._id);   
    }
  }
}
