<div class="nico-centent">
    <div class="head-bloc" *ngIf="topic">
        <p class="title">
            <span class="frm" [routerLink]="['/forum']">Forum <i class="bi bi-chevron-right"></i> </span>
            <span class="frm" [routerLink]="['/topic', topic._id]"> {{topic.title}} <i class="bi bi-chevron-right"></i></span>
            <span class="crtf">  {{"aTraining" | translate}}</span>
        </p>
    </div>
    <div class="body-content">        
        <div class="row">
            <div class="col" *ngIf="topic">
                <p class="topic-title">{{topic.title}}</p>
                <div class="topic-category">        
                    <span>{{topic.category.name | translate}}</span>
                </div>
            </div>
            <div class="col vote-time" *ngIf="remainingDays">
                <div class="owner">
                    <span class="material-symbols-outlined clock">alarm</span>
                    <span class="numbers">{{remainingDays}} {{"remainDays" | translate}}</span>
                </div>
            </div>
        </div>
        <div id="container-pdf" class="pdf-container " *ngIf="training">
            <pdf-viewer [src]="training.file_name"
            id="viewer-pdf"
              style="width: 100%; height: 408px"
              [original-size]="true"
            ></pdf-viewer>
        </div>
        <div class="btn-actions" *ngIf="topic">
            <button class="formation back" *ngIf="isTrainingFollowed" [routerLink]="['/topic', topic._id]">{{"back" | translate}}</button>
            <button class="formation back" *ngIf="!isTrainingFollowed" [ngClass]="{ 'disabled': isTrainingFollowed }" (click)="followTraining()" [disabled]="isTrainingFollowed">{{"validTraining" | translate}}</button>
            <button class="formation" *ngIf="training" (click)="downloadTraining(training._id)">{{"downloadit" | translate}} 
                <span class="material-symbols-outlined">description</span>
            </button>
            <!--<a href="{{auth.getApiBasePath()+'training/pdf-file/'+training._id}}"></a>-->
        </div>
    </div>
</div>


<span class="material-symbols-outlined" id="open-loading" data-bs-toggle="modal" data-bs-target="#loadingModal"></span>
 <!-- Modal loading -->
 <div class="modal fade" id="loadingModal" tabindex="-1" data-bs-backdrop="static" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
        <div class="modal-body">
            <div class="title">
                <p class="modal-title" id="exampleModalLabel">{{"loading" | translate}}</p>
                <button type="button" id="close-loading" data-bs-dismiss="modal" aria-label="Close">*</button>
            </div>
            <div class="load"></div>
            <div class="fusee">
                <span>{{"thanksPatien" | translate}}<br> {{"savingInBlock" | translate}}</span>
                <img src="assets/images/fusee_.png" alt="Fusée">
            </div>
        </div>        
        </div>
    </div>
 </div> 