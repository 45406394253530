<div class="nico-centent">
    <div class="head-bloc">
        <p class="title">Forum</p>
        <div id="step5">
            <p class="topic-news">{{"topTopic" | translate}}</p>
            <div class="most-topic" *ngIf="inNewsTopic">
                <div class="row">
                        <div class="col-md-8">
                            <p class="topic-title" [routerLink]="['/topic/',inNewsTopic._id]">
                                {{inNewsTopic.title}}
                            </p>
                            <p class="see-resume" [routerLink]="['/topic/',inNewsTopic._id]">
                                {{inNewsTopic.description}}
                            </p>
                        </div>
                    <div class="col-md-2 stats">
                            <div class="row">
                            <div class="col metric" *ngIf="inNewsTopic.participants">
                                <!--<span class="material-symbols-outlined">
                                    campaign
                                    </span>
                                <span class="numbers">{{inNewsTopic.participants.length}} Participants</span>-->
                            </div>
                        <!--<div class="col metric">
                                <span class="material-symbols-outlined">
                                    alarm
                                    </span>
                                <span class="numbers">5 jours restants</span>
                            </div>
                            <div class="col metric">
                                <span class="material-symbols-outlined">
                                    data_usage
                                    </span>
                                <span class="numbers">20% de participation</span>
                            </div>-->
                        </div>                   
                    </div>
                </div>
                <div class="show-detail">
                    <a [routerLink]="['/topic/',inNewsTopic._id]" class="new-topic">{{"seeTopic" | translate}}  
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="body-bloc">
        <div class="head-list">
            <div class="row">
                <div class="col-md-2">
                    <h2>{{"allTopics" | translate}}</h2>
                </div>
                <div class="col-md-10 filter-bloc">
                    <mat-slide-toggle [color]="color" (change)="showArchivedTopics($event)" id="archived-btn" >{{"archived" | translate}} </mat-slide-toggle>
                    <input type="text" placeholder="{{'search' | translate}}.." [(ngModel)]="searchTxt" (input)="onSearchChange($event)">
                    <select (ngModelChange)="selectCategory($event)" [(ngModel)]="selectCat">
                        <option value="" >{{"category" | translate}}</option>
                        <option value="all" >{{"allCategories" | translate}}</option>
                        <ng-container *ngFor="let categorie of categories">
                            <option *ngIf="!filter.categories.includes(categorie._id)" value="{{categorie._id}}">{{categorie.name | translate}}</option>
                        </ng-container>
                    </select>
                </div>
                <ul class="category-list">
                    <ng-container *ngFor="let categorie of categories">
                        <li  *ngIf="filter.categories.includes(categorie._id)" >
                            <span class="cat-name">{{categorie.name}}</span>
                            <span class="material-symbols-outlined close" (click)="removeCatFromFilter(categorie._id)">cancel</span>
                        </li>
                    </ng-container>                    
                </ul>
                <div class="topics-container">
                    <!-- favorite Topics -->
                    <div class="topic-bloc" *ngFor="let topic of favoriteTopics">
                        <div class="row">
                            <div class="col-md-2 tt">
                                <div class="tit">
                                    <h3 [routerLink]="['/topic/',inNewsTopic._id]">{{topic.title}} </h3>
                                    <i title="{{'topTopic' | translate}}" *ngIf="currentUser && currentUser.role === 'Admin'" class="in-news bi" [ngClass]="topic.in_news ? 'bi-bookmark-check-fill' : 'bi-bookmark-check'" (click)="selectInNewsTopic(topic._id, true)"></i>
                                </div>
                                <span>{{topic.category.name | translate}}</span>
                            </div>
                            <div class="col-md-9">
                                <p [routerLink]="['/topic/',inNewsTopic._id]">{{topic.description}}</p>
                            </div>
                            <div class="col-md-1 interction">  
                                <div>
                                    <i class="bi bi-star-fill fill" (click)="pushOrPullFavorite(topic._id)"></i> 
                                </div>
                                <span class="material-symbols-outlined show-topic" [routerLink]="['/topic', topic._id]">
                                    arrow_forward_ios
                                </span>                               
                            </div>
                        </div>
                    </div> 
                    <!-- Topics rest -->
                    <div class="topic-bloc" *ngFor="let topic of topics">
                        <div class="row">
                            <div class="col-md-2 tt">
                                <div class="tit">
                                    <h3 [routerLink]="['/topic/',topic._id]">{{topic.title}} </h3>
                                    <div class="admin-action">
                                        <i title="{{'topTopic' | translate}}" class="in-news bi" [ngClass]="topic.in_news ? 'bi-bookmark-check-fill' : 'bi-bookmark-check'" (click)="selectInNewsTopic(topic._id, false)"></i>
                                        <br>
                                        <i title="{{'archived' | translate}}" class="in-news bi" [ngClass]="topic.archived ? 'bi-archive-fill' : 'bi-archive'" *ngIf="currentUser && currentUser.role === 'Admin'" (click)="topicToArchive(topic._id)"></i>
                                    </div>
                                </div>
                                <span>{{topic.category.name | translate}}</span>
                            </div>
                            <div class="col-md-9">
                                <p [routerLink]="['/topic/',topic._id]">{{topic.description}}</p>
                            </div>
                            <div class="col-md-1 interction">
                                <div>
                                    <i class="bi bi-star" (click)="pushOrPullFavorite(topic._id)"></i>                                                              
                                </div>
                                <span class="material-symbols-outlined show-topic" [routerLink]="['/topic', topic._id]">
                                    arrow_forward_ios
                                </span>                                                             
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="new-topic-bloc" >
                        <a id="step6" [routerLink]="['/new-topic']" class="new-topic">{{"newTopic" | translate}}  
                            <span class="material-symbols-outlined">arrow_forward_ios</span>
                        </a>
                </div>
            </div>
        </div>
    </div>
</div>