<div class="nico-centent">    
    <div class="head-bloc" *ngIf="topic" id="step6">
        <p class="title"><span class="frm" [routerLink]="['/forum']">Forum <i class="bi bi-chevron-right"></i> </span><span class="crtf">  {{topic.title}}</span></p>
        <div class="row">
            <div class="col state-vote">
                <span class="vote-state">{{showVoteState()}}</span>
                <i class="bi star" *ngIf="topic && currentUser" [ngClass]="isFavorite ? 'bi-star-fill' : 'bi-star'" (click)="pushOrPullFavorite()"></i> 
            </div>
            <div class="col see-topic">
                <button *ngIf="!showTopicInfo" (click)="showTopicInfo = !showTopicInfo">
                    {{"openTopic" | translate}} 
                    <i class="bi bi-chevron-right show-topic"></i>
                </button>
                <button *ngIf="showTopicInfo" (click)="showTopicInfo = !showTopicInfo">
                    <i class="bi bi-chevron-left show-topic"></i>
                    {{"closeTopic" | translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="body-content">
        <div class="row topic-bloc">
            <div class="col chat-topic-bloc " *ngIf="!(isMobile && showTopicInfo)">
                <app-chat [topic]="topic" [election]="election"></app-chat>
            </div>
            <div class="col info-topic-bloc" [ngClass]="{'sup-bottom-margin': isClosedVote()}" *ngIf="showTopicInfo">
                <app-topic-info [topic]="topic" [election]="election"></app-topic-info>
            </div>
        </div>
        <div class="btn-actions" *ngIf="topic" id="step8">
            <div class="content-btn" *ngIf="is_ok_to_vote">
                <div class="admin-part" *ngIf="currentUser">
                    <button class="formation" *ngIf="currentUser.role === 'Admin' && oldTraining" data-bs-toggle="modal" data-bs-target="#addFormationModal">{{"update" | translate}} {{"training" | translate}}</button>
                    <button class="formation" *ngIf="currentUser.role === 'Admin' && !oldTraining" data-bs-toggle="modal" data-bs-target="#addFormationModal">{{"add" | translate}} {{"training" | translate}}</button>
                    <button class="vote " [ngClass]="{'disabled': !oldTraining}" *ngIf="currentUser.role === 'Admin' && oldTraining && !election" [disabled]="!oldTraining" data-bs-toggle="modal" data-bs-target="#voteModal">{{"openVote" | translate}}</button>
                </div>
                <div class="user-part" *ngIf="topic">
                    <button class="formation" [routerLink]="['/training',topic._id]" [ngClass]="{'disabled': !oldTraining}" [disabled]="!oldTraining">{{"getTraining" | translate}}</button>
                    <button *ngIf="affectedVote" class="vote" data-bs-toggle="modal" data-bs-target="#voteProductModal" [ngClass]="{'disabled': !isVoteAvailable()}" [disabled]="!isVoteAvailable()" (click)="openModalToVote()">{{"updateVote" | translate}}</button>
                    <button *ngIf="!affectedVote" class="vote" data-bs-toggle="modal" data-bs-target="#voteProductModal" [ngClass]="{'disabled': !isVoteAvailable()}" [disabled]="!isVoteAvailable()" (click)="openModalToVote()">{{"vote" | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal add formation  -->
<div class="modal fade delete-update-modal" *ngIf="currentUser && currentUser.role === 'Admin'" id="addFormationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header-mod">
          <p class="modal-title" id="exampleModalLabel">{{"addTraining" | translate}}</p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body-mod">
            <div class="uploaded-bloc">
                <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
                    <path d="M38 43.0996H46.9062C53.3516 43.0996 58.625 39.6766 58.625 33.3027C58.625 26.9289 52.4141 23.7555 47.375 23.5059C46.3332 13.5379 39.0547 7.47461 30.5 7.47461C22.4141 7.47461 17.2063 12.8406 15.5 18.1621C8.46875 18.8301 2.375 23.3043 2.375 30.6309C2.375 37.9574 8.70312 43.0996 16.4375 43.0996H23" stroke="#005F86" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M38 29.9746L30.5 22.4746L23 29.9746M30.5 52.5238V24.3496" stroke="#005F86" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div class="info-upload">
                    <div class="cnx">
                        <span class="info">{{"dragDrop" | translate}}</span>
                        <span class="up">{{"upload" | translate}}</span>
                    </div>
                    <div>
                        <span class="ext">
                            {{"acceptPdf" | translate}}
                        </span>
                    </div>
                </div>
                <input type="file" name="training" (change)="onFileChange($event)" accept="application/pdf" id="trainingFile">
            </div>
            <div *ngIf="trainingFile">
                <p>{{"download" | translate}}</p>
                <div class="uploaded-file">
                    <span class="file-name">{{trainingFile}}</span>
                    <span class="material-symbols-outlined trash" (click)="deleteTraining()">delete_forever</span>
                </div>
            </div>
        </div>
        <div class="modal-footer-mod">
          <button type="button" class="btn btn-update" data-bs-dismiss="modal" [disabled]="disableSendTrainig" (click)="uploadTrainigFile()">
                {{"save" | translate}} 
                <span class="material-symbols-outlined save-ic">arrow_forward_ios</span>
            </button>
        </div>
      </div>
    </div>
</div>

<!-- Modal vote for admin create vote  -->
<div class="modal fade delete-update-modal" id="voteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header-mod">
          <p class="modal-title" id="exampleModalLabel">{{"openVote" | translate}}</p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body-mod">
            <div class="inp-group">
                <label for="startDateInp">{{"dateBegin" | translate}}</label>
                <input matInput [matDatepicker]="datepicker" id="startDateInp" placeholder="Sélectionnez une date" [(ngModel)]="startDate">
                <mat-datepicker-toggle matSuffix [for]="datepicker" class="date-picker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>                
            </div>
            <div class="inp-group">
                <label for="startDateInpEnd">{{"dateEnd" | translate}}</label>
                <input matInput [matDatepicker]="datepicker2" id="startDateInpEnd" placeholder="Sélectionnez une date" [(ngModel)]="endDate">
                <mat-datepicker-toggle matSuffix [for]="datepicker2" class="date-picker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker2></mat-datepicker>                
            </div>
            <div class="inp-group">
                <label for="optionsInp">Option(s)</label>
                <input type="text" [(ngModel)]="optionInp" id="optionsInp" (keydown.enter)="enterToAddOption($event)" placeholder="Oui...">
                <button class="add-item-btn" (click)="enterToAddOption($event)">
                    <span class="material-symbols-outlined">add</span>
                    {{"add" | translate}}
                </button>
            </div>
            <div class="option-list" *ngIf="options.length">
                <span class="option-item" *ngFor="let option of options; index as i">{{option}} 
                    <span class="material-symbols-outlined" (click)="deleteOption(i)">close</span>
                </span>
            </div>
        </div>
        <div class="modal-footer-mod">
          <button type="button" class="btn btn-update" data-bs-dismiss="modal" (click)="openVote()">{{"openVote" | translate}}</button>
        </div>
      </div>
    </div>
</div>

<!-- Modal vote for product vote  -->
<div class="modal fade delete-update-modal" id="voteProductModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header-mod">
          <p class="modal-title" id="exampleModalLabel">Vote</p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body-mod">
            <div class="option-list" *ngIf="options.length && !isVoteSended">
                <span class="option-item proposal" (click)="selectProposal(i)" [ngClass]="{'active': selectedIndex==i}" *ngFor="let option of options; index as i">{{option}}</span>
            </div>
            <div class="msg-info" *ngIf="isVoteSended">
                <span>{{"thanksForPar" | translate}} <br> {{"savedVote" | translate}}</span>
            </div>
        </div>
        <div class="modal-footer-mod">
          <button type="button" class="btn btn-update" *ngIf="!isVoteSended && affectedVote" (click)="sendVote()">{{"updateVote" | translate}}</button>
          <button type="button" class="btn btn-update" *ngIf="!isVoteSended && !affectedVote" (click)="sendVote()">{{"vote" | translate}}</button>
          <button type="button" class="btn btn-update" *ngIf="isVoteSended" data-bs-dismiss="modal">{{"close" | translate}}</button>
        </div>
      </div>
    </div>
</div>

<span class="material-symbols-outlined" id="open-loading" data-bs-toggle="modal" data-bs-target="#loadingModal"></span>
 <!-- Modal loading -->
 <div class="modal fade" id="loadingModal" tabindex="-1" data-bs-backdrop="static" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
        <div class="modal-body">
            <div class="title">
                <p class="modal-title" id="exampleModalLabel">{{"loading" | translate}}</p>
                <button type="button" id="close-loading" data-bs-dismiss="modal" aria-label="Close">*</button>
            </div>
            <div class="load"></div>
            <div class="fusee">
                <span>{{"thanksPatien" | translate}}<br> {{"savingInBlock" | translate}}</span>
                <img src="assets/images/fusee_.png" alt="Fusée">
            </div>
        </div>        
        </div>
    </div>
 </div> 
