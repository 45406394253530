import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { AuthUser } from "../models";
import { Router } from "@angular/router";
import * as $ from 'jquery';
import { TranslateService } from "@ngx-translate/core";
declare var introJs: any;

@Component({
  selector: "my-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent {
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  handleSidebarToggle = (close: boolean = false) =>{ 
    if(close) {
      this.toggleSidebar.emit(false);
    }else{
      this.toggleSidebar.emit(!this.isExpanded);
    }
  }
  constructor(private auth: AuthService, 
              private _router:Router,
              private translate: TranslateService){}
  
  toHide: boolean;
  currentUser: AuthUser;
  modal = 0;
  followZoneTitle = 'Coming soon ... La Zone de suivi';
  followZoneTxt = "Une zone qui retrace l'historique de tous les sujets clos. Ne manquez rien et voyez les progrès réalisés !";
  MyTopicsTitle = 'Coming soon ... Mes sujets';
  MyTopicsTxt = "Une page dédiée aux sujets que vous avez créé, à vos sujets favoris et à ceux que vous suivez de près !";
  ngOnInit(){
    if(this.auth.currentUser()){
      this.currentUser = this.auth.currentUser();
      if((!this.currentUser.tutorial && window.innerWidth > 991) && this.auth.getFirstInterface()){
        setTimeout(()=>this.initIntroJs(), 500);
      }
    }else{
      this.getCurrentUser();
    }
    if(window.innerWidth < 992){
      this.toHide = true;
    }
  }

  @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      if(event.target.innerWidth < 992){
        this.toHide = true;
      }else{
        this.toHide = false;
      }
  }

  getAb(firstN: string, lastN: string):string{
    return firstN[0]+lastN[0]
  }
  
  getCurrentUser(){
    let vm=this;
    this.auth.apiCall('user/current-user','get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          this.auth.setCurrentUser(res);
          this.currentUser = res
          if((!res.tutorial && window.innerWidth > 991) && vm.auth.getFirstInterface()){
            setTimeout(()=>vm.initIntroJs(), 500);
          }
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }

  initIntroJs(): void {    
    let vm = this;
    introJs()
      .setOptions({
        tooltipClass: 'customTooltip',
        highlightClass: 'customHighlight',
        exitOnOverlayClick: false,
        disableInteraction: false,
        showBullets: false,
        steps: [
          {
            title: (vm.currentUser.language === 'fr') ? 
                    'Bienvenue'
                    : "Welcome",
            intro: (vm.currentUser.language === 'fr') ? 
                    'Bienvenue sur la Nicosphère, laissez-moi vous faire visiter !'
                  : "Welcome to the Nicosphere, allow me to give you a tour!"
          },
          {
            element: document.getElementById('step1'),
            title: 'Navigation',
            intro: (vm.currentUser.language === 'fr') ? 
                      " Utilisez les icônes pour naviguer sur la plateforme. Retrouvez les différents espaces : <ul class='ul-list'> <li>Votre dashboard (à venir)</il><li>Le forum</li><li>Vos sujets (à venir)</li><li>La zone de suivi (à venir)</li><ul>"
                      : " Use the icons to navigate the platform. Find the different spaces : <ul class='ul-list'> <li>Your dashboard (upcoming)</il><li>The forum</li><li>Your topics (upcoming)</li><li>Tracking area (upcoming)</li><ul>",
            position: 'right'
          },
          {
            element: document.getElementById('step3'),
            title:  (vm.currentUser.language === 'fr') ? 
                      'Vos icônes'
                    : 'Your icons',
            intro: (vm.currentUser.language === 'fr') ?
                  'Retrouvez trois icônes vous permettant d’accéder à votre porte-feuille, les notifications et la langue.'
                  :'Find three icons allowing you to access your wallet, notifications and language.',
            position: 'bottom'
          }
        ]
      })
      .start();
      this.cutomizingTooltip();
      this.handlBtn()
  }

  cutomizingTooltip(){
    let vm = this;
    $(".introjs-skipbutton").text((vm.currentUser.language === 'fr') ? 'Passer le tutoriel': 'Skip the tutorial')
    $( ".introjs-nextbutton" ).html((vm.currentUser.language === 'fr') ?'Commencer': 'Start');
    $( ".introjs-prevbutton" ).html( '<i class="bi bi-chevron-left"></i>');
    $( ".introjs-prevbutton" ).addClass('hide-btn');
    $('.introjs-button').attr('data-index', 0)
  }

  handlBtn(){
    let vm = this;
    $('.introjs-nextbutton').click((e: any)=>{
      this.auth.setOnBoardingStepNext();
      const i = $('.introjs-prevbutton').attr('data-index');
      $( ".introjs-nextbutton" ).html('<i class="bi bi-chevron-right"></i>');
      if(i){
        $('.introjs-button').attr('data-index', parseInt(i)+1);
        if(i === '0'){
          $( ".introjs-nextbutton" ).html('<i class="bi bi-chevron-right"></i>');
          if(!$(".counter")[0]){
            $( "<span class='counter'>1/8</span>" ).insertAfter( ".introjs-prevbutton" );
          }
          $( ".introjs-prevbutton" ).removeClass('hide-btn');
        }else{
          $( ".counter" ).html((parseInt(i)+1)+'/8');                  
        }
      }else{    
        this._router.navigate(['/forum']);
      }   
    });

    $('.introjs-prevbutton').click(()=>{
      this.auth.setOnBoardingStepPrev();
      const i = $('.introjs-button').attr('data-index');
      $( ".introjs-nextbutton" ).html('<i class="bi bi-chevron-right"></i>');
      if(i){
        $('.introjs-button').attr('data-index', parseInt(i)-1);
        if(i ==='1'){
          $( ".introjs-nextbutton" ).html((vm.currentUser.language === 'fr') ?'Commencer': 'Start');
          $( ".counter" ).remove();
        }else{
          $( ".counter" ).html((parseInt(i)-1)+'/8');
        }
      }
    });

    $('.introjs-skipbutton').click(()=>{
      this.auth.affectedTutorial();
    });    
  }  
}
