import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { environment } from 'environments/environment';
const axios = require('axios').default;

//declare var $: any;
@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
})

export class AuthComponent implements OnInit {

	constructor(private msalService: MsalService, private auth: AuthService, private _router:Router) { }
	ngOnInit(): void {
		this.msalService.handleRedirectObservable().subscribe({
			next: (result) => {
				if (result) {
					this.log_request(result);
				}
			},
			error: (error) => {
				console.error('Error during redirect', error);
			}
		});
	}

	private async log_request(result: any) {
		console.log('Login successful');
		console.log('call backend api');
		const res = await axios({
			baseURL: environment.APIEndpoint,
			url: 'auth/login',
			method: 'post',
			data: { accessToken: result.accessToken, idToken: result.idToken, kid: jwtDecode(result.accessToken, { header: true }) },
			headers: { Authorization: result.idToken },
		})
		if (res)
		{
			localStorage.setItem('accessToken_entraID', result.accessToken)
			localStorage.setItem('idToken_entraID', result.idToken)
			localStorage.setItem('access_token', res.data)
			// console.log("dans le localstorage", localStorage.getItem('access_token'));
			this._router.navigate(['/forum'])
		}
		console.log("et voila !!", result);
	}

	login(): void {
		const request: any = {
			scopes: ['user.read']
		};
		this.msalService.loginRedirect(request);
	}

	logout() {
		// juste vider la memoire cache je pense que c'est mieux.
		this.msalService.logoutPopup({
			mainWindowRedirectUri: "/"
		});
	}
}
