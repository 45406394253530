<div class="nico-centent">
    <div class="gray-bloc"></div>
    <div class="row">
        <div class="col-md-12"  *ngIf="isMobile">
            <div class="info-text">
                <div class="show-info"></div>
                <span>
                    {{"dashboardInfo" | translate}}
                </span>
            </div>
        </div>
        <div class="col-md-12" *ngIf="isMobile">
            <div class="margin-div"></div>
        </div>
        <div class="col-md-9">            
            <div class="bl contribution-bloc">
                <p class="title">{{"contribution" | translate}}</p>
                <div class="graph">
                    <img src="assets/images/static-dashboard/graph.jpg" alt="Graph exemple">
                </div>
            </div>
            <!--<img src="assets/images/static-dashboard/top-left.svg" alt="logo">-->
        </div>
        <div class="col-md-12" *ngIf="isMobile">
            <div class="margin-div"></div>
        </div>
        <div class="col-md-3"> 
            <div class="bl top-contribution">
                <div class="head">
                    <span class="title">{{"topContr" | translate}}</span>
                    <img src="assets/images/static-dashboard/Rank.png" alt="Lang">
                </div>
                <div class="top-cont">
                    <img src="assets/images/static-dashboard/Rectangles.jpg" alt="">
                </div>
            </div>         
        </div>
        <div class="col-md-12">
            <div class="margin-div"></div>
        </div>
        <div class="col-md-6">
            <div class="bl feed-bloc">
                <div class="head">
                    <span>{{"feed" | translate}}</span>
                    <p class="select-b">{{"filtredBy" | translate}} <i class="bi bi-chevron-down"></i></p>
                </div>
                <div class="acts">
                    <img src="assets/images/static-dashboard/activities_.jpg" alt="Disc">
                </div>
            </div>          
           <!--<img src="assets/images/static-dashboard/bottom-left.svg" alt="logo">-->
        </div>
        <div class="col-md-12" *ngIf="isMobile">
            <div class="margin-div"></div>
        </div>
        <div class="col-md-6">            
            <!--<img src="assets/images/static-dashboard/bottom-right.svg" alt="logo">-->
            <div class="bl last-news">
                <p class="title">{{"sinceLAstCnx" | translate}}</p>
                <div class="new-img">
                    <img src="assets/images/static-dashboard/msgs.jpg" alt="">
                </div>                
            </div>
            <!--<div class="row coming-bloc">
                <div class="col">
                    <div class="coming-favorite">
                        <span>Bientôt, votre sujet favori</span>
                    </div>
                </div>
                <div class="col">
                    <div class="coming-follow">
                        <span>Bientôt, votre sujet le plus suivi</span>
                    </div>
                </div>
            </div>-->
            <div class="info-text" *ngIf="!isMobile">
                <div class="show-info"></div>
                <span>
                    {{"dashboardInfo" | translate}}
                </span>
            </div>
        </div>
    </div>
</div>
<div class="on-boarding-mobile" *ngIf="showMobileTuto">
    <p class="skip-tuto" (click)="skipTuto()">{{"skipTuto" | translate}}</p>
    <div class="title">
        <span class="txt" [innerHTML]="tutoList[pageI].title"></span>
    </div>
    <div class="img-c">
        <img src="assets/images/onboarding/{{tutoList[pageI].img}}">
    </div>
    <div class="body-cont">
        <p class="sub-title" [innerHTML]="tutoList[pageI].subTitle"></p>
        <p class="txt-content" [innerHTML]="tutoList[pageI].desc"></p>
    </div>
    <div class="footer">
        <ul>
            <li [ngClass]="{'active': pageI===0}"></li><li [ngClass]="{'active': pageI===1}"></li>
            <li [ngClass]="{'active': pageI===2}"></li><li [ngClass]="{'active': pageI===3}"></li>
            <li [ngClass]="{'active': pageI===4}"></li>
        </ul>
        <button (click)="nextTuto()" *ngIf="(pageI < 4)">{{"next" | translate}}</button>
        <button (click)="nextTuto()" *ngIf="!(pageI < 4)">{{"begin" | translate}}</button>
    </div>

</div>