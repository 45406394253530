import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TopicI } from '../models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-topic',
  templateUrl: './add-topic.component.html',
  styleUrls: ['./add-topic.component.scss']
})
export class AddTopicComponent implements OnInit{
  title : string = "";
  category : string = "";
  description : string = "";
  categories : any[]= [];
  oldTopic: TopicI;
  erros : any = {'title': '', 'category': '', 'description': ''};
  isToEdit: boolean = false;
  requiredMsg: string = 'Ce champ est obligatoire*'
  existTitle: string = 'Ce titre existe déjà !'
  constructor(private auth: AuthService, private _router:Router, private route: ActivatedRoute, private translate: TranslateService){}

ngOnInit(): void {
  this.listCategory();
  this.route.params.subscribe(params => {
    if(params['id']){
      this.getTopic(params['id']);
    }
  });
}

  verifyForm(): boolean{
    this.erros.title = ''; this.erros.category = ''; this.erros.description = '';
    if(!this.title.length){
      this.erros.title = this.requiredMsg;
      return false;
    }
    if(!this.category.length){
      this.erros.category = this.requiredMsg;
      return false;
    }
    if(!this.description.length){
      this.erros.description = this.requiredMsg;
      return false;
    }
    return true;
  }
  listCategory(){
  let vm=this;
    this.auth.apiCall('category/list','get',{}, true).subscribe(        
      result => {
        // Handle result
        vm.categories = result as any;
              
      },
      error => {          
        console.log(error)
      },
      () => {         
      });
  }

  getTopic(id: string){
    let vm=this;
    this.auth.apiCall('forum/topic/'+id,'get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          vm.oldTopic = res as any;
          vm.title = res.title;
          vm.category = res.category._id;
          vm.description = res.description;
          //vm.isToEdit = true;
        }
      },
      error => {          
        console.log('error',error)
      },
      () => {         
      });
  }

  sendTopic(){
    if(this.verifyForm()){
      let vm=this;
      let channels= [
        {"name": "Général", 'description': "Ici, il est question de challenger l’idée, de proposer des améliorations, de poser vos question…"},
        //{"name": "General", 'description': "Here, it's about challenging the idea, suggesting improvements, asking your questions..."},
        {"name": "Technique", 'description': "Dans ce canal, on se pose les questions techniques. Technologies utilisées, logiciels, machines…"},
        //{"name": "Technical", 'description': "In this channel, we ask ourselves technical questions. (Technologies used, softwares, machines…"},        
        {"name": "Finance", 'description': "Ici, on aborde les questions financières. Combien ça coute ? Où trouver le budget ? …"},
        //{"name": "Finance", 'description': "Here, we speak about financial questions. How much does it cost ? Where do we find the budget ? …"},
        {"name": "Règles de vote", 'description': "Ce canal a pour but de poser les règles,et tous les questionnements associés au vote. Temps de vote ? Nombre de votants ? Majorité ? …"},
        //{"name": "Voting rules", 'description': "This chanel is here to think about the voting rules. How long is the voting time ? How many voters ? Majority ? …"}
      ];
      let data = {
              _id: (vm.oldTopic && vm.oldTopic._id) ?  vm.oldTopic._id : null,
              title: this.title,
              description:this.description,
              channels ,
              category: this.category
            }
      this.auth.apiCall('forum/create-update-topic','post',data, true).subscribe(        
        result => {
          // Handle result
          const r:any = result;
          if(r.success){
            if(vm.oldTopic && vm.oldTopic._id){
              this._router.navigate(['/topic/',vm.oldTopic._id]);               
            }else{
              this._router.navigate(['/forum']);          
            }
          }else{
            console.log('ERROR', result)
          }          
        },
        error => {         
          if(error && error.error.message){
            if(error.error.message === "title already exist"){
              vm.erros.title = vm.existTitle;
            }
          }
        });
    }
  }
}

