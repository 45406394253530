<div class="nico-centent">
    <div class="row tokens-cont">
        <div class="col-md-3 token-cont">
            <div class="conter">
            <div class="body-tok">
                <img class="simple-token" src="assets/images/Vote-token.png">
                <p class="token-name vote">{{"voteToken" | translate}}</p>
                <p class="token-desc">
                    {{"vtDif" | translate}}
                    <span class="sub-title">{{"acquisition" | translate}}</span>
                    {{"vt1" | translate}}
                    <span class="sub-title">{{"purpose" | translate}}</span>
                    {{"vt2" | translate}}
                </p>
            </div>
            </div>
        </div>
        <div class="col-md-3 token-cont">
            <div class="conter">
            <div class="body-tok">                
                <img class="simple-token" src="assets/images/Maturity-token.png">
                <p class="token-name matu">{{"maturToken" | translate}}</p>                
                <p class="token-desc">
                    {{"matTDif" | translate}}
                    <span class="sub-title">{{"acquisition" | translate}}</span>
                    {{"matT1" | translate}}
                    <span class="sub-title">{{"purpose" | translate}}</span>
                    {{"matT2" | translate}}
                </p>
            </div>
            </div>
        </div>
        <div class="col-md-3 token-cont">
            <div class="conter">
                <div class="body-tok">
                    <img class="simple-token" src="assets/images/Xp-Token.png">
                    <p class="token-name exp">{{"expToken" | translate}}</p>
                    <p class="token-desc">
                        {{"expTDif" | translate}}<span class="sub-title">{{"acquisition" | translate}}</span>
                        {{"expT1" | translate}}
                        <span class="sub-title">{{"purpose" | translate}}</span>
                        {{"expT2" | translate}}
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-3 token-cont nico-cont">
            <div class="conter">
                <div class="body-tok">
                    <img class="nico-img" src="assets/images/Nico.png">
                    <p class="token-name nico">Nicocoin</p>
                    <p class="token-desc">
                        {{"nicoCoinDesc" | translate}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>