<div class="sidebar-container">  
  <ng-container *ngIf="showNav">
      <my-sidebar [isExpanded]="sidebarExpanded" (toggleSidebar)="hundelSidebarExpanded($event)"></my-sidebar>
  </ng-container>
  <div class="content" [ngClass]="{ 'sidebarExpanded': sidebarExpanded }">
    <div class="c-content" [ngClass]="{ 'blue-background': blueBackground }">
      <ng-container *ngIf="showNav">
        <app-navbar [isExpanded]="sidebarExpanded" (toggleSidebar)="hundelSidebarExpanded($event)" ></app-navbar>
      </ng-container>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
