import { Component } from '@angular/core';
import { ElectionI, TopicI, TrainingI } from '../models';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent {

  constructor(private route: ActivatedRoute, public auth: AuthService, private _router:Router, private translate: TranslateService) { }
  topic: TopicI;
  training: TrainingI;
  election: ElectionI;
  options: string[] = [];
  selectedIndex: number;
  isTrainingFollowed: boolean = false;
  remainingDays : number;

  getTopic(id: string){
    let vm=this;
    this.auth.apiCall('forum/topic/'+id,'get',{}, true).subscribe(        
      result => {
        vm.topic = result as any; 
        vm.getVote(vm.topic._id)             
      },
      error => {          
        console.log('error',error)
      },
      () => {         
      });
  }

  downloadTraining(id: string){
    this.auth.getUploadedFile('training/pdf-file/'+id).subscribe(response => this.downLoadFile(response, "application/pdf"));
  }  
  /**
   * Method is use to download file from server.
   * @param data - Array Buffer data
   * @param type - type of the document.
  */
  downLoadFile(data: any, type: string) {
   var blob = new Blob([data], { type: type.toString() });
   var url = window.URL.createObjectURL(blob);
   var pwa = window.open(url);
   if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
     alert('Please disable your Pop-up blocker and try again.');
    }
  }
  
  selectProposal(i: number){
    this.selectedIndex = i;
  }

  getTraining(topicId: string){
    let vm=this;
    this.auth.apiCall('training/'+topicId,'get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          this.training = res;
          this.getIfTrainingFollowed();
          setTimeout(()=>this.checkPdfHeight(),500)
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }

  getVote(topicId: string){
    let vm=this;
    this.auth.apiCall('vote/vote-topic/'+topicId,'get',{}, true).subscribe(        
      result => {
        const res: ElectionI = result as any;
        if(res && res._id){
          vm.calculateVoteRemainingDays(res)
        }
      },
      error => {          
        console.log('Error',error)
      });
  }

  calculateVoteRemainingDays(election: ElectionI){
    if(election && election.startDate && election.endDate){
      if(new Date() >=  new Date(election.startDate) && new Date() < new Date(election.endDate)){
        this.remainingDays = this.auth.calculateDayDifference(new Date().toISOString(), election.endDate)
      }
    }
  }

  followTraining(){
    // this.openLoadingModal()
    let vm=this;
    this.auth.apiCall('training/recieve/'+vm.training._id,'get',{}, true).subscribe(        
      result => {
        const res = result as any;
        vm.isTrainingFollowed = true; 
        // vm.closeLoadingModal()
        this._router.navigate(['/topic/',vm.topic._id]);                   
      },
      error => {    
        // setTimeout(()=>vm.closeLoadingModal(), 500);      
        console.log('Error',error)
      });
  }

  getPdfUrl(url:string){
    return  { url, withCredentials: true }
  }

  getIfTrainingFollowed(){
    let vm=this;
    this.auth.apiCall('training/isfollow/'+vm.training._id,'get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res.exist){
          vm.isTrainingFollowed = res.exist;
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }

  openLoadingModal(){
    $( "#open-loading" ).trigger( "click" );
  }

  closeLoadingModal(){
    $( "#close-loading" ).trigger( "click" );
  }

  checkPdfHeight(){
    const content = $('#container-pdf') as any;
    $('#viewer-pdf').height(content.height());  
  }


  ngOnInit() {    
    this.route.params.subscribe(params => {
      this.getTopic(params['id']);
      this.getTraining(params['id'])
    });
  }

}
