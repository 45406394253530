<div class="container text-center">
    <div class="row justify-content-center align-items-center vh-100">
        <div class="col-md-8">
            <img src="assets/images/nico-logo.png" alt="Logo" class="mb-4" />
            <h1 class="display-4">{{"welcome" | translate}}</h1>
            <button id="boutton-connexion"  (click)="login()" class="btn btn-primary btn-lg mt-4">Se connecter</button>
            <p class="mt-3 text-muted">
                Vous serez redirigés vers la page de connexion Microsoft avec vos identifiants Nicomatic habituels
            </p>
        </div>
    </div>
</div>
<div class="astronaut">
    <img src="assets/images/auth/astronaute.png" alt="Astronaut" />
</div>
<div class="rocket">
    <img src="assets/images/auth/fusee.png" alt="Rocket" />
</div>
  