import { Component, HostListener } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { MsalService } from '@azure/msal-angular';

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD"
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "YYYY-MM-DD",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' }
  ]
})
export class AppComponent {
  constructor(private router:Router, private authService: MsalService){
    //router.changes.subscribe((val) => /*whatever*/)      
  }

  title = 'Nicosphere';
  sidebarExpanded = false;
  toHideSideBar: boolean = false;
  showNav = false;
  blueBackground =  true;
  navBarAvailableFor: string[] = ["dashboard", "forum", "new-topic", "topic", "training", "edit-topic", "tokens"];
  backGroundBlueNotAvailableFor : string[] = [ "topic", "forum", 'tokens'];
  ngOnInit() {

    this.authService.instance.handleRedirectPromise().then(() => {
      if (this.authService.instance.getAllAccounts().length > 0) {
        // User is already signed in
        console.log("user logged with microsoft");
      }
    }).catch(error => {
      console.error(error);
    });

    this.router.events.subscribe((event: any) =>{
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/');
        this.showNav = this.navBarAvailableFor.includes(url[1]);
        this.blueBackground = !this.backGroundBlueNotAvailableFor.includes(url[1]);

      }
    });    

    $('.content').click((e)=>{
      if(e.target.className !== 'navbar-toggler-icon'){
        this.sidebarExpanded = false;
      }
    })
  }

  hundelSidebarExpanded(event: boolean){
    this.sidebarExpanded = event;
  }
}
