import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForumComponent } from './forum/forum.component';
import { AddTopicComponent } from './add-topic/add-topic.component';
import { TopicComponent } from './topic/topic.component';
import { TrainingComponent } from './training/training.component';
import { TokensComponent } from './tokens/tokens.component';
const routes: Routes = [
  {
    path: '',
    component: AuthComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'forum',
    component: ForumComponent
  },
  {
    path: 'new-topic',
    component: AddTopicComponent
  },
  {
    path: 'edit-topic/:id',
    component: AddTopicComponent
  },
  { 
    path: 'topic/:id',
    component: TopicComponent 
  },
  { 
    path: 'training/:id',
    component: TrainingComponent
  },
  {
    path: 'tokens',
    component: TokensComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
