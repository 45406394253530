import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SocketService } from '../services/socket.service';
import { AuthService } from '../services/auth.service';
import { AuthUser, NotificationI } from '../models';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent{
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private notif: SocketService,
              private auth: AuthService,
              private _router:Router,
              private route: ActivatedRoute,
              private msalService: MsalService,
              private translate: TranslateService){}
  wallet: string;
  balance: string;
  currentUser: AuthUser;
  currentDate: string;
  notificationCount: number = 0;
  notifications : NotificationI[];
  newNotif: NotificationI[] = [];
  oldNotif: NotificationI[] = [];
  audio = new Audio();
  language: string;
  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);

  getCurrentDate(): string{
    const event = new Date();
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const dateLang = (this.currentUser.language == 'en') ? 'en' : 'fr';
    return event.toLocaleDateString(dateLang, options as any);
  }

  switchIt(){
    this.auth.apiCall('user/switch-admin','get',{}, true).subscribe(        
      result => {
        console.log('ex: ',result)                
      },
      error => {          
        console.log('error',error)
      });
  }

  getAllNotif(){
    let vm = this;
    this.auth.apiCall('notification/all','get',{}, true).subscribe(        
      result => {
        const res: NotificationI[] = result as any;
        vm.notifications = res;
        if(res && res.length){
          for (const notif of res) {
            if(notif.seen || notif.users_seen.includes(vm.currentUser._id)){
              vm.oldNotif.push(notif);
            }else{
              vm.newNotif.push(notif);
            }
          }
          vm.notificationCount = this.newNotif.length;
        }
      },
      error => {          
        console.log('error',error)
      });
  }

  notificationDateTimeDifference(notificationDate:string): string{
    const today: any = new Date();
    const notifDate: any = new Date(notificationDate);
    const diffMs = (today - notifDate); // milliseconds between now & notif date
    const diffDays = Math.floor(diffMs / 86400000); // days
    if(diffDays){
      if(diffDays === 1){
        return (this.currentUser.language === 'fr') ? 'Hier' : 'Yest'
      }
      return diffDays+((this.currentUser.language === 'fr') ? ' j' : 'd')
    }
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    if(diffHrs){
      if(diffHrs>1){
        return diffHrs+' h'
      }else{
        return diffHrs+' h'
      }
    }
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if(diffMins){
      return diffMins+'min';
    }
    return  (this.currentUser.language === 'fr') ? 'maint' : 'know' ;
  }

  displayNotification(notif: NotificationI): string{
    const more_info = JSON.parse(notif.more_info);
    const newTopicTxt = (this.currentUser.language === 'fr') ? 'a créé le topic': 'has created the topic';
    const cameToPartTxt = (this.currentUser.language === 'fr') ? 'viens en discuter': 'come discuss';
    const newCommentTxt = (this.currentUser.language === 'fr') ? 'a répondu à votre message sur le topic' : 'replied to your message on topic';
    if(notif.type === "new_topic"){
      return `<strong>@${more_info.user_last_name}_${more_info.user_first_name}</strong> ${newTopicTxt} <strong>${more_info.topicTitle}</strong>, ${cameToPartTxt} !`;
    }else if(notif.type === "message_response"){
      return `<strong>@${more_info.user_last_name}_${more_info.user_first_name}</strong> ${newCommentTxt} <strong>${more_info.topicTitle}</strong>`
    }else{
      return "new notification type must implemented"
    }
  }

  redirectToNotification(notif: NotificationI){
    if(notif.type === 'new_topic' && notif.link){      
      this._router.navigate(['/topic/',notif.link]);  
    }else if(notif.type === 'message_response' && notif.link){      
      this._router.navigate(['/topic/',notif.link],
      { queryParams: { msg: 'popular' } });  
    }
    $( "#close-notif-modal" ).trigger( "click" );
  }

  checkVisibility(notif: any):boolean{
    return notif.not_visible_for_user.includes(this.currentUser._id);
  }

  removeElementAt(arr: any, index: number): any {
    let frontPart = arr.slice(0, index);
    let lastPart  = arr.slice( index+1 ); // index to end of array
    return [...frontPart, ...lastPart];
  }

  playNotifSound(){      
      this.audio.play();
  }

  hideNotif(id: string, index: number, old:boolean = true){
    this.auth.apiCall('notification/disable/'+id,'get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res.success && res.updated){
          if(old)
            this.oldNotif= this.removeElementAt(this.oldNotif, index);
          else
            this.newNotif= this.removeElementAt(this.newNotif, index);
        }               
      },
      error => {          
        console.log('error',error)
      });
  }

  seeAllNotif(){
    let vm = this;
    this.auth.apiCall('notification/seen-all','get',{}, true).subscribe(        
      result => {
        const res = result as any; 
        if(res.updated){       
          vm.notificationCount = 0;          
        }
      },
      error => {          
        console.log('error',error)
      });
  }

  makeAllNotifSeen(){
    this.oldNotif = [...this.newNotif, ...this.oldNotif];
    this.newNotif = [];
    this.notificationCount = 0;  
  }
  
  ngOnInit() {
    this.getBalance();
    this.audio.src = "../../../assets/audio/ton.mp3";
    this.audio.load();
    if(this.auth.currentUser()){
      this.currentUser = this.auth.currentUser();
      if(this.currentUser.language){
        this.language = this.currentUser.language;      
        this.translate.use(this.language);
        this.currentDate = this.getCurrentDate();
      }
      this.getAllNotif();
      this.hundelUserNotif();
      this.hundelNotifForAll();
    }else{
      this.getCurrentUser();
    }
  }

  logout(){
    this.msalService.logoutRedirect({
			// mainWindowRedirectUri: "/"
		});
    localStorage.clear();
    // localStorage.setItem("access_token", "");
  }

  getBalance(){
    let vm=this;
    this.auth.apiCall('user/nvv-balance','get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res.wallet){
          vm.wallet = res.wallet;
          vm.balance = res.balance;
        }
      },
      error => {          
        console.log('error',error)
      },
      () => {         
      });
  }

  onChangeLanguage(){
    this.callToChangeLanguage(this.language)
  }

  callToChangeLanguage(lang: string){
    let vm=this;
    this.auth.apiCall('user/change-language/'+lang,'get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res.success && res.language){
          if(vm.currentUser.language){
            vm.language = res.language;
            vm.translate.use(vm.language);
            vm.currentUser.language = vm.language;
            vm.auth.setCurrentUser(vm.currentUser);
            vm.currentDate = vm.getCurrentDate()
          }
        }
      },
      error => {          
        console.log('error',error)
      },
      () => {         
      });
  }

  getCurrentUser(){
    let vm=this;
    this.auth.apiCall('user/current-user','get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          vm.auth.setCurrentUser(res);
          vm.currentUser = res;
          if(vm.currentUser.language){
            vm.language = vm.currentUser.language; 
            vm.translate.use(vm.language);
            vm.currentDate = vm.getCurrentDate();
          }
          this.getAllNotif();
          vm.hundelUserNotif();
          vm.hundelNotifForAll();
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }

  hundelUserNotif(){
    let vm = this;    
      this.notif.socket.on("for_user_"+vm.currentUser._id, function(data: any) {
        if(data._id){
          vm.newNotif.unshift(data);
          vm.notificationCount++;
          //vm.playNotifSound()
        }
      });
  }

  hundelNotifForAll(){
    let vm = this;    
      this.notif.socket.on("for_all", function(data: any) {
        console.log('all notification', data)
        if(data && data.from && data.from !== vm.currentUser._id){
          vm.newNotif.unshift(data);
          vm.notificationCount++;
          //vm.playNotifSound()
        }
      });
  }

}
