import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services/auth.service';
import * as $ from 'jquery';
import { AuthUser } from '../models';
import { TranslateService } from '@ngx-translate/core';
declare var introJs: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent {

  constructor(private auth: AuthService, private translate: TranslateService) {}
  currentUser: AuthUser;
  isMobile: boolean = false;
  pageI: number = 0;
  showMobileTuto : boolean = false;
  tutoList = [
    {
      title: "Navigation",
      subTitle:  (this.translate.currentLang === 'en') ? "Stay connected to the community around the world." : "Restez connectés à la communauté autour du monde.",
      img: "astronaute 2.png",
      desc: (this.translate.currentLang === 'en') ?
              "The Nicoosphere gives you the opportunity to access different spaces and functionalities through icons. Have a good trip !"
            : " La Nicosphère vous donne l'opportunité d'accéder à différents espaces et fonctionnalités aux travers des icônes.  Bon voyage !"
    },
    {
      title: "<i class='bi bi-grid'></i> Dashboard",
      subTitle: (this.translate.currentLang === 'en') ?
            "View a summary of platform activities."    
            :"Visualisez un résumé des activités de la plateforme.",
      img: "astronaute 3.png",
      desc: (this.translate.currentLang === 'en') ?
            "Find essential information: <ul class='ty'><li>Statistics</li><li>The latest events</li><li>What happened in your absence</li> "
          : "Retrouvez les informations essentielles : <ul class='ty'><li>Des statistiques</li><li>Les derniers évènements</li><li>Ce qu’il s’est passé en votre absence</li>"
    },
    {
      title: "<i class='bi bi-chat-square-dots'></i> Forum",
      subTitle: (this.translate.currentLang === 'en') ? 
                "Build the future of Nicomatic together."
                :"Construisez ensemble l’avenir  de Nicomatic.",
      img: "astronaute 4.png",
      desc: (this.translate.currentLang === 'en') ? 
            "Participate in current and future topics, improve your skills with the associated training and vote. A new idea, share it !"
            :"Participez aux sujets en cours et à venir, montez en compétences avec la formation associée et votez. Une nouvelle idée, partagez-là !"
    },
    {
      title: (this.translate.currentLang === 'en') ? 
              "<i class='bi bi-bookmark'></i> My topics <span>(upcoming)</span>"
            : "<i class='bi bi-bookmark'></i> Mes sujets <span>(à venir)</span>",
      subTitle: (this.translate.currentLang === 'en') ? 
              "The space that centralizes your particular interests."
            : "L’espace qui centralise vos intérêts particuliers.",
      img: "astronaute 5.png",
      desc: (this.translate.currentLang === 'en') ? 
              "<ul class='ty'><li>Your favorite topics</li><li>Les sujets que vous suivez de près  (basé sur votre participation)</li><li>Vos sujets créés</li>"
            : "<ul class='ty'><li>Vos sujets favoris</li><li>The topics you follow closely (based on your participation)</li><li>Your created topics</li>"
    },
    {
      title: (this.translate.currentLang === 'en') ?
              "<i class='bi bi-pie-chart'></i> Tracking area <span>(upcoming)</span>"
            : "<i class='bi bi-pie-chart'></i> Zone de suivi <span>(à venir)</span>",
      subTitle: (this.translate.currentLang === 'en') ?
                "Find the history of the platform, don’t miss anything."
              : "Retrouvez l’historique de la plateforme, ne manquez rien.",
      img: "astronaute 6.png",
      desc: (this.translate.currentLang === 'en') ?
              "Check out past topics, discussions, and courses."
            : "Consultez les sujets, les discussions et les formations passées."
    },
  ]

  ngOnInit(): void {
    if(this.auth.currentUser()){
      this.currentUser = this.auth.currentUser();
      if(!this.currentUser.tutorial){
        if(this.auth.getLastOnBoarding()){
          this.initIntroJs();
        }
      }
    }else{
      this.getCurrentUser();
    }    
    if(window.innerWidth < 767){
      this.isMobile = true;
    }
  }

  
  @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      if(event.target.innerWidth < 767){
        this.isMobile = true;
      }else{
        this.isMobile = false;
      }
  }
  
  nextTuto(){
    if(this.pageI === 4){
      this.showMobileTuto = false;      
      this.auth.affectedTutorial();
    }else{
      this.pageI++;
    }
  }

  initIntroJs(): void {
    let vm=this;
    introJs()
      .setOptions({
        tooltipClass: 'customTooltip',
        highlightClass: 'customHighlight',
        exitOnOverlayClick: false,
        disableInteraction: false,
        showBullets: false,
        steps: [
          {
            title: (vm.currentUser.language === 'fr') ? 'À vous de jouer !': "It's your turn!",
            intro: (vm.currentUser.language === 'fr') ? 
                  "Vous avez finis la visite. Il est temps de planter votre drapeau sur la Nicosphère ! <br> Si vous avez une question, n'hésitez pas à nous contacter à cette adresse :  <br> <i class='link'>nicosphere@nicomatic.ch</i>" 
                  : 
                  "You have finished the visit. It's time to plant your flag on the Nicosphere! <br> If you have a question, do not hesitate to contact us at this address: <br> <i class='link'>nicosphere@nicomatic.ch</i>"
          }
        ]
      })
      .start();
      this.cutomizingTooltip();
      this.auth.affectedTutorial();
  }

  skipTuto(){
    this.showMobileTuto = false;
    this.auth.affectedTutorial();
  }

  getCurrentUser(){
    let vm=this;
    this.auth.apiCall('user/current-user','get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          vm.auth.setCurrentUser(res);
          this.currentUser = res
          if(!res.tutorial){
            if(window.innerWidth < 992){
              this.showMobileTuto = true;
            }
            if(vm.auth.getLastOnBoarding()){
              vm.initIntroJs();
            }
          }
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }

  cutomizingTooltip(){
    let vm = this;
    $(".introjs-skipbutton").text((vm.currentUser.language === 'fr') ? 'Passer le tutoriel': 'Skip the tutorial')
    $( ".introjs-nextbutton" ).html('<i class="bi bi-check-lg"></i>');
    $( ".introjs-prevbutton" ).addClass('hide-btn');
    $( ".introjs-nextbutton" ).addClass('last-one');
    //handel pass Tutorial click
    $('.introjs-skipbutton').click(()=>{
      this.auth.affectedTutorial();
    });
  }

 
}
