import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthUser, ElectionI, TopicI, TrainingI, VoteI } from '../models';
import { AuthService } from '../services/auth.service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';
declare var introJs: any

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss']
})
export class TopicComponent {

  constructor(private route: ActivatedRoute, private auth: AuthService, private _router:Router, private translate: TranslateService) {
	interface JwtPayload {
		exp: number,
		firstName: string,
		iat: number,
		lastName: string,
		role: string,
		sub: string,
		username: string,
		wallet: string
	}

	const id_sujet_assemblage = "Répartition des augmentations individuelles - MU Assemblage"
	// const id_sujet_assemblage = "animal"
	const assemblage = ["r.bliez@nicomatic.ch", "j.albouc@nicomatic.fr", "a.allouch@nicomatic.fr", "r.bakalem@nicomatic.fr", "c.barbier@nicomatic.fr", "e.bercasio@nicomatic.fr", "o.bertin@nicomatic.fr", "j.careme@nicomatic.fr", "ml.cart@nicomatic.fr", "f.chourfi@nicomatic.fr", "d.couton@nicomatic.fr", "a.delerce@nicomatic.fr", "m.dubois@nicomatic.fr", "i.ferdinand@nicomatic.fr", "s.gaulet@nicomatic.fr", "a.girod@nicomatic.fr", "n.gueguen@nicomatic.fr", "j.guilhot@nicomatic.fr", "d.hatton@nicomatic.fr", "a.hottier@nicomatic.fr", "b.jacquier@nicomatic.fr", "v.jacquier@nicomatic.fr", "m.josse@nicomatic.fr", "mt.lamoussiere@nicomatic.fr", "a.laout@nicomatic.fr", "m.lausenaz@nicomatic.fr", "f.lus@nicomatic.fr", "a.martina@nicomatic.fr", "a.niermarechal@nicomatic.fr", "s.olivo@nicomatic.fr", "j.racine@nicomatic.fr", "d.rochereuil@nicomatic.fr", "v.rochette@nicomatic.fr", "f.rognard@nicomatic.fr", "e.sahin@nicomatic.fr", "l.salvi@nicomatic.fr", "m.vesin@nicomatic.fr"];
	const token = localStorage.getItem('access_token') as any;
	const decoded : JwtPayload = jwtDecode(token);
	var id_vote;
	var name_topic = "";
	this.route.params.subscribe(params => {
		id_vote = params['id'];
		console.log(id_vote, id_sujet_assemblage)
		// const name_topic = apicall get topic name by id
	  });
	this.auth.apiCall('forum/topic/'+id_vote,'get',{}, true).subscribe(        
		result => {
			let test : any;
			test = result;
			name_topic = test.title;
			// console.log("nammmmmmme: ", name_topic)
			// console.log("yoyoyoyoyo", assemblage.indexOf(decoded.username), decoded.username)
			if (id_sujet_assemblage != name_topic)
			{
				// console.log("PAS meme nom donc true");
				this.is_ok_to_vote = true;
			}
			// if (name_topic == id_vote && assemblage.indexOf(decoded.username) != -1)
			if (id_sujet_assemblage == name_topic && assemblage.indexOf(decoded.username) != -1)
			{
				// console.log("meme nom + dans la liste donc true");
				this.is_ok_to_vote = true;
			}

		},
		error => {          
			console.log('error',error)
		},
		() => {         
	});
  }

  showTopicInfo: boolean = true;
  isMobile: boolean = false;
  topicId : string = "";
  topic : TopicI;
  isFavorite: boolean = false;
  currentUser: AuthUser;
  election : ElectionI;
  oldTraining: TrainingI;  
  trainingFile : any;
  formData:FormData = new FormData();
  disableSendTrainig : boolean = true;
  isTrainingFollowed: boolean = false;
  isVoteSended: boolean = false;
  options: string[] = [];
  optionInp: string = "";
  selectedIndex: number;
  affectedVote: VoteI;
  startDate: string = "";
  endDate: string = "";
  is_ok_to_vote = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const width = event.target.innerWidth;
    if(width < 992){
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }
  }

  checkAffectedTuto(){
    if(this.currentUser && !this.currentUser.tutorial && this.auth.getOnBoardingStep()>0){
      this.initIntroJs()
    }
  }

  getTopic(id: string){
    let vm=this;
    this.auth.apiCall('forum/topic/'+id,'get',{}, true).subscribe(        
      result => {
        vm.topic = result as any;
        vm.checkIfIsInFavorite();
        vm.getVote();
        vm.getTraining()
        setTimeout(()=>vm.checkAffectedTuto(), 700)        
                
      },
      error => {          
        console.log('error',error)
      },
      () => {         
      });
  }

  isVoteAvailable():boolean{
    if(this.election && this.isTrainingFollowed &&
       (new Date(this.election.startDate) < new Date()) && (new Date(this.election.endDate) > new Date())){
        return true
    }
    return false;
  }

  isVoteClosed():boolean{
    if(this.election && new Date(this.election.endDate) > new Date()){
        return true
    }
    return false;
  }
  isClosedVote(){
    if(this.election && (new Date(this.election.endDate) < new Date())){
      return true;
    }
    return false;
  }

  showVoteState(){
    if(this.election){
      if(new Date(this.election.startDate) > new Date()){
        return (this.translate.currentLang === 'en') ? 'Upcoming vote' : 'Vote à venir' ;
      }else if(new Date(this.election.startDate) < new Date() && (new Date(this.election.endDate) > new Date())){
        return (this.translate.currentLang === 'en') ? 'Vote open' : 'Vote ouvert';
      }else if(new Date(this.election.endDate) < new Date()){
        return (this.translate.currentLang === 'en') ? 'Vote closed' : 'Vote fermé';
      }
    }
    return (this.translate.currentLang === 'en') ? 'Upcoming vote' : 'Vote à venir';
  }

  getVote(){
    let vm=this;
    this.auth.apiCall('vote/vote-topic/'+vm.topic._id,'get',{}, true).subscribe(        
      result => {
        const res: ElectionI = result as any;
        if(res && res._id){
          vm.election = res;
            this.options = this.election.proposals;      
            this.getAffectedVote();    
        }
      },
      error => {          
        console.log('Error',error)
      });
  }

  checkIfIsInFavorite(){
    let vm = this;
    this.isFavorite = this.topic.favorites?.some(f=>f.user === vm.currentUser._id) || false;
  }

  ngOnInit() {
    //check if current user exsit in auth service
    this.hundelQueryParams()
    if(this.auth.currentUser()){
      this.currentUser = this.auth.currentUser(); 
    }else{
      this.getCurrentUser();
    }

    this.route.params.subscribe(params => {
      this.getTopic(params['id']);
    });

    if(window.innerWidth < 992){
      this.isMobile = true;
    }
  }

  hundelQueryParams(){
    let vm = this;
    this.route.queryParamMap.subscribe(params => {
        const isMsgRedirect = params.get('msg');
        if(isMsgRedirect){
          this.showTopicInfo = false; 
        }
      
      });
  }

  pushOrPullFavorite(){
    let vm=this;
    this.auth.apiCall('forum/topic/favorite/'+vm.topic._id,'get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res.length){
          vm.isFavorite = false;
        }else{
          vm.isFavorite = true;
        }
      },
      error => {          
        console.log('error',error)
      },
      () => {         
      });
  }

  getCurrentUser(){
    let vm=this;
    this.auth.apiCall('user/current-user','get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          vm.auth.setCurrentUser(res);
          vm.currentUser = res;
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }

  onFileChange(event: any){
    if(event.target && event.target.files&& event.target.files.length){
      let fileList: FileList = event.target.files;      
      let file: File = fileList[0];
      this.formData.append('file', file, file.name);
      this.trainingFile = file.name;
      this.disableSendTrainig = false;
    }

  }

  deleteTraining(){
    this.formData.delete('file');
    this.trainingFile = "";    
    this.disableSendTrainig = true;
    $('#trainingFile').val('');
  }

  getIfTrainingFollowed(){
    let vm=this;
    this.auth.apiCall('training/isfollow/'+vm.oldTraining._id,'get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res.exist){
          vm.isTrainingFollowed = res.exist;
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }

  enterToAddOption($event: any){
    if(this.optionInp.length){
      this.options.push(this.optionInp);
      this.optionInp= ""
    }
  }

  deleteOption(index: number){
    this.options.splice(index, 1);
  }

  getTraining(){
    let vm=this;
    this.auth.apiCall('training/'+vm.topic._id,'get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          this.oldTraining = res;
          const ar = this.oldTraining.file_name.split("/");
          this.trainingFile = ar[ar.length-1];
          vm.getIfTrainingFollowed();
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }

  uploadTrainigFile(){
    let vm = this;
    const file = this.formData.get('file')
    if(this.trainingFile.length && file){
      this.formData.append('topic', this.topic._id);
      if(this.oldTraining && this.oldTraining._id){
        this.formData.append('_id', this.oldTraining._id);
      }
        // this.openLoadingModal();
        this.auth.apiCall('training/create-update','post',this.formData, true, true as any).subscribe(        
          result => {
            const res: any = result;  
            if(res._id){
              this.oldTraining = res;
              const ar = this.oldTraining.file_name.split("/");
              this.trainingFile = ar[ar.length-1];
              vm.getIfTrainingFollowed();
              // vm.closeLoadingModal();
            }   
          },
          error => {          
            // vm.closeLoadingModal();
            console.log('error',error)
      });
    }
  }

  showVoteSuccessMessage(){
    this.isVoteSended = true;
  }

  
  openModalToVote(){
    this.isVoteSended = false;
  }
  
  selectProposal(i: number){
    this.selectedIndex = i;
  }
  
  sendVote(){
    if(this.selectedIndex>=0){
      const data = {     
        proposalIndex: this.selectedIndex.toString(),
        electionId: this.election._id
      };
      let vm=this;
      this.auth.apiCall('vote/send-vote','post',data, true).subscribe(        
        result => {
          const newVote: VoteI = result as any;
          if(newVote && newVote._id){
            vm.affectedVote = newVote;
            vm.showVoteSuccessMessage();          
          }               
        },
        error => {          
          console.log('Error',error)
        },
        () => {         
      });
    }
  }

  openVote(){
    const data = {
      topicTitle: this.topic.title,
      topic: this.topic._id, 
      electionStartDate: this.startDate,
      electionEndDate: this.endDate,
      proposals: this.options
    };
    this.openLoadingModal();
    let vm=this;
    this.auth.apiCall('vote/create','post',data, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          vm.election = res;
          vm.closeLoadingModal();
        }          
      },
      error => {
        vm.closeLoadingModal();       
        console.log('Error',error)
      });
  }

  getAffectedVote(){
    let vm=this;
    this.auth.apiCall('vote/vote-affected/'+vm.election._id,'get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          this.affectedVote = res;
          this.selectedIndex = parseInt(this.affectedVote.proposal)          
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }

  openLoadingModal(){
    $( "#open-loading" ).trigger( "click" );
  }

  closeLoadingModal(){
    $( "#close-loading" ).trigger( "click" );
  }

  initIntroJs(): void {
    introJs()
      .setOptions({
        tooltipClass: 'customTooltip',
        highlightClass: 'customHighlight',
        exitOnOverlayClick: false,
        disableInteraction: false,
        showBullets: false,
        steps: [          
          {
            element: document.getElementById('step6'),
            title: 'Topic',
            intro:  (this.translate.currentLang === 'en') ? 
                 `You have arrived on topic: <br> <ul class='ul-list'> <li>Use the tree structure to locate and navigate</il><li>View the voting status</li><li>Use the star to bookmark me</li><li>Click on "Open topic" to access details</li><ul>` 
                :`Vous êtes arrivés sur un sujet : <br> <ul class='ul-list'> <li>Utilisez l’arborescence pour vous situer et naviguer</il><li>Visualisez le statut du vote</li><li>Utilisez l'étoile pour me mettre en favoris</li><li>Cliquez sur "Ouvrir le sujet" pour accéder aux détails</li><ul>`,
            position: 'bottom'
          },
          {
            element: document.getElementById('step7'),
            title: 'Tchat',
            intro: (this.translate.currentLang === 'en') ? 
                 "Find here the discussion channels of the topic, the chat:<br> <ul class='ul-list'> <li>Choose the discussion channel on the left</il> <li>Do a search </li> < li>React to and/or respond to a message</li> <li>Send a message</li></ul>" 
                :"Retrouvez ici les canaux de discussion du topic, le chat :<br> <ul class='ul-list'> <li>Choisir le canal de discussion à gauche</il> <li>Faire une recherche </li> <li>Réagir à un message et/ou y répondre</li> <li>Envoyer un message</li></ul>",
            position: 'top'
          },
          {
            element: document.getElementById('step8'),
            title: (this.translate.currentLang === 'en') ? "Buttons" :'Les boutons',
            intro: (this.translate.currentLang === 'en') ? "If voting is open, you have the opportunity to vote for the topic. You will be required to complete the associated training before you can vote." :'Si le vote est ouvert, vous avez la possibilité de voter pour le topic. Il vous sera demandé de suivre la formation associée avant de pouvoir voter.',
            position: 'top'
          }
        ]
      })
      .start();
      this.cutomizingTooltip();
      this.handlBtn();
  }

  cutomizingTooltip(){
    $(".introjs-skipbutton").text((this.translate.currentLang === 'en') ? 'Skip the tutorial' :'Passer le tutoriel')
    $( ".introjs-nextbutton" ).html('<i class="bi bi-chevron-right"></i>');
    $( ".introjs-prevbutton" ).html( '<i class="bi bi-chevron-left"></i>');    
    $( ".introjs-prevbutton" ).addClass('hide-btn'); 
    $('.introjs-button').attr('data-index', 6)
    $( "<span class='counter'>6/8</span>" ).insertAfter( ".introjs-prevbutton" );    
    $(".introjs-helperLayer").addClass("top-z-index");  
  }

  handlBtn(){
    $('.introjs-nextbutton').click((e)=>{
      this.auth.setOnBoardingStepNext();
      const i = $('.introjs-button').attr('data-index');
      $( ".introjs-nextbutton" ).html('<i class="bi bi-chevron-right"></i>');      
      $(".introjs-helperLayer").addClass("top-z-index");  
      if(i){
        $('.introjs-button').attr('data-index', parseInt(i)+1);       
        $( ".counter" ).html((parseInt(i)+1)+'/8');               
      }else{
          this.auth.setLastOnBoarding(true);
          this._router.navigate(['/dashboard']);
      }
    });  

    $('.introjs-prevbutton').click(()=>{
      this.auth.setOnBoardingStepPrev();
      const i = $('.introjs-button').attr('data-index');      
      $(".introjs-helperLayer").addClass("top-z-index");       
      $( ".introjs-nextbutton" ).html('<i class="bi bi-chevron-right"></i>');
      if(i){
        $('.introjs-button').attr('data-index', parseInt(i)-1);
        if(i ==='7'){         
          $( ".introjs-prevbutton" ).addClass('hide-btn');          
        }
        $( ".counter" ).html((parseInt(i)-1)+'/8');
        
      }
    });

    $('.introjs-skipbutton').click(()=>{
      this.auth.affectedTutorial();
    });
  }
}
