<nav class="navbar navbar-expand-lg navbar-light">
    <div class="row">
        <div class="col nav-toggle">
            <button class="navbar-toggler btn-nav-tog" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="handleSidebarToggle()">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
        <div class="col">            
            <ul class="date-ul">
                <li *ngIf="currentUser">
                  <span class="current-date">{{currentDate}}</span>
                </li>
            </ul>
            <ul class="icon-iu" id="step3">
                <li class="notif" (click)="seeAllNotif()">
                  <i class="bi bi-bell " data-bs-toggle="modal" data-bs-target="#notifModal">
                    <span id="notif-number" *ngIf="notificationCount">{{notificationCount}}</span>
                  </i>
                </li>
                <li>
                  <i class="bi bi-wallet2" data-bs-toggle="modal" data-bs-target="#walletModal"></i>            
                </li>
                <li>
                  <i class="bi bi-globe2" data-bs-toggle="modal" data-bs-target="#languageModal"></i>
                </li>
                <li class="dropdown" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div class="dropdown">                        
                    <i class="bi bi-power"></i>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" [routerLink]="['']" (click)="logout()">{{"Logout" | translate}}</a>
                    </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
  </nav>
  
  <!-- Modal Language -->
  <div class="modal fade fade" id="languageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="title">
            <p class="modal-title" id="exampleModalLabel">{{"lang" | translate}}</p>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <p>{{"choiceLang" | translate}}</p>
          <select class="form-select" (change)="onChangeLanguage()" [(ngModel)]="language">
            <option value="fr" >{{"fr" | translate}}</option>
            <option value="en" >{{"en" | translate}}</option>
          </select>
        </div>        
      </div>
    </div>
  </div>

  <!-- Modal Notification -->
  <div class="modal fade fade" id="notifModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="title">
            <p class="modal-title" id="exampleModalLabel">Notification</p>
            <button type="button" class="close" id="close-notif-modal" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <p class="make-all-seen" (click)="makeAllNotifSeen()">{{"seeAll" | translate}}</p>
          <div class="notif-content">
            <div class="notification new-notif" *ngIf="newNotif && newNotif.length">
              <p class="title">{{"new"| translate}}</p>
              <ng-container  *ngFor="let notif of newNotif; let i = index"> 
                <div class="notif-detail" *ngIf="!checkVisibility(notif)">
                  <p class="notif-txt" [innerHTML]="displayNotification(notif)" (click)="redirectToNotification(notif)"></p>
                  <p class="time">
                    <span class="minute">{{notificationDateTimeDifference(notif.createdAt)}}</span> 
                    <span class="material-symbols-outlined" (click)="hideNotif(notif._id, i, false)">cancel</span>
                  </p>
                </div>
              </ng-container>
            </div>
            <div class="notification old-notif" *ngIf="oldNotif && oldNotif.length">
              <p class="title">{{ "old" | translate}}</p>
              <ng-container  *ngFor="let notif of oldNotif; let i = index">                
                <div class="notif-detail" *ngIf="!checkVisibility(notif)">
                  <p class="notif-txt" [innerHTML]="displayNotification(notif)" (click)="redirectToNotification(notif)"></p>
                  <p class="time">
                    <span class="minute">{{notificationDateTimeDifference(notif.createdAt)}}</span> 
                    <span class="material-symbols-outlined" (click)="hideNotif(notif._id, i)">cancel</span>
                  </p>
                </div>
              </ng-container>
              </div>
            </div>
          </div>
        </div>        
      </div>
  </div>

    <!-- Modal wallet -->

  <div class="modal fade" id="walletModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">        
        <div class="modal-body">
          <div class="head-bloc">
            <p class="wallet-title">{{"myTokenWal" | translate}}</p>            
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>          
          <p class="address-wallet" *ngIf="wallet">{{"walletAdd" | translate}}: <a class="hx" target="_blank" href="https://goerli.etherscan.io/address/{{wallet}}">{{wallet}}</a></p>
          <div class="body-bloc">
            <div class="row">
              <div class="col bl tv">
                <p class="token-balance" *ngIf="balance">{{balance}}</p>
                <img class="simple-token" src="assets/images/Vote-token.png">
                <span class="token-name">{{"voteToken" | translate}}</span>
              </div>
              <div class="col bl tm">
                <p class="token-balance">0</p>
                <img class="simple-token" src="assets/images/Maturity-token.png">
                <span class="token-name">{{"maturToken" | translate}}</span>
              </div>
              <div class="col bl tex">
                <p class="token-balance">0</p>
                <img class="simple-token" src="assets/images/Xp-Token.png">
                <span class="token-name">{{"expToken" | translate}}</span>
              </div>
              <!--<div class="col tt">
                <p class="token-balance">0</p>
                <img src="assets/images/Time-token.png">
                <span>Token de temps</span>
              </div>-->
              <div class="col bl nicocoin">
                <p class="token-balance">0</p>
                <img class="nico-img" src="assets/images/Nico.png">
                <span class="token-name">Nicocoin</span>
              </div>
            </div>
            <div class="more-info">
              <a [routerLink]="['/tokens']" data-bs-dismiss="modal" aria-label="Close">{{"moreInfo" | translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
