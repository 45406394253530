import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AuthUser, TopicFavorite, TopicI } from '../models';
import {ThemePalette} from '@angular/material/core';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
declare var introJs: any;

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent{
  constructor(private auth: AuthService, private _router:Router,
    private translate: TranslateService){}
  topics: TopicI[] = [];
  allTopics : TopicI[] = [];
  favoriteTopics: TopicI[] = [];
  categories : any[]= [];
  allCategoriesId: string[];
  selectCat: string = '';
  inNewsTopic: TopicI;
  currentUser: AuthUser;
  searchTxt: string = '';
  filter = { text: '', categories: this.categories, archived: false };
  color: ThemePalette = 'warn';
  firstIt = false;
  firstTopicId : string;
  
  ngOnInit(): void {
    this.listCategory();
    //check if current user exsit in auth service
    if(this.auth.currentUser()){
      this.currentUser = this.auth.currentUser();      
      this.listTopics();
      if(!this.currentUser.tutorial && this.auth.getOnBoardingStep()>0){
        setTimeout(()=>this.initIntroJs(), 500);
      }
    }else{
      this.getCurrentUser();
    }    
  }

  selectCategory(newValue: string){
    if(newValue.length){
      if(newValue === 'all'){
        this.filter.categories = [...this.allCategoriesId];
      }else{
        this.filter.categories.push(newValue);
      }
      this.listTopics();
    }else{
      this.filter.categories = []
    }
  }

  haveSameContents(a: string[], b: string[]){
    for (const v of new Set([...a, ...b]))
      if (a.filter(e => e === v).length !== b.filter(e => e === v).length)
        return false;
    return true;
  };
  

  updateTheInNewsTopic(_id: string, inFavorite: boolean){    
    if(inFavorite){
      for (const i in this.favoriteTopics) {
        if (this.favoriteTopics[i]._id === _id) {
          this.favoriteTopics[i].in_news = true;
          this.inNewsTopic = this.favoriteTopics[i];
          break;
        }
      }
    }else{
      for (const i in this.topics) {
        if (this.topics[i]._id === _id) {
          this.topics[i].in_news = true;
          this.inNewsTopic = this.topics[i];
          break;
        }
      }      
    }
  }

  deleteOldinNews(){
    const index = this.favoriteTopics.map(e => e.in_news).indexOf(true);
    if(index>= 0){
      this.favoriteTopics[index].in_news = false;
    }else{
      const index = this.topics.map(e => e.in_news).indexOf(true);
      if(index>= 0){
        this.topics[index].in_news = false;
      }
    }
  }

  removeCatFromFilter(id: string){
    const index = this.filter.categories.indexOf(id);
    if (index > -1) {
      this.filter.categories.splice(index, 1); 
    }
    this.selectCat = '';
    this.listTopics()
  }

  updateTopicList(topicId: string, data: TopicFavorite[]){
    if(data.length){
      const index = this.favoriteTopics.map(e => e._id).indexOf(topicId);
      if(index>-1){
        this.topics.push(this.favoriteTopics[index]);
        this.favoriteTopics.splice(index, 1); 
      }
    }else{
      const index = this.topics.map(e => e._id).indexOf(topicId);
      if(index>-1){
        this.favoriteTopics.push(this.topics[index]);
        this.topics.splice(index, 1); 
      }
    }
  }

  onSearchChange(searchValue: any): void { 
    let vm = this;
    if(this.searchTxt.length){
      this.distributeTopics(this.allTopics.filter((m)=>m.title.toLocaleLowerCase().includes(vm.searchTxt.toLocaleLowerCase()) || m.description.toLocaleLowerCase().includes(vm.searchTxt.toLocaleLowerCase())));
    }else{
      this.distributeTopics(this.allTopics);
    }
  }

  showArchivedTopics(event: any){
    if(event.checked){
      this.filter.archived = true;
    }else{
      this.filter.archived = false;
    }
    this.listTopics()
  }

  selectInNewsTopic(id: string, inFavorite: boolean){
    if(!this.inNewsTopic || this.inNewsTopic._id !== id){
      let vm=this;
      this.auth.apiCall('forum/topic/inthenews/'+id,'get',{}, true).subscribe(        
        result => {
          const res = result as any;
          if(res.success){
            vm.deleteOldinNews();
            vm.updateTheInNewsTopic(id, inFavorite)
          }           
        },
        error => {          
          console.log(error)
        });
    }
  }

  topicToArchive(id: string){
    if(!this.inNewsTopic || this.inNewsTopic._id !== id){
      let vm=this;
      this.auth.apiCall('forum/archive/'+id,'get',{}, true).subscribe(        
        result => {
          const res = result as any;
          if(res.update){
            vm.listTopics()
          }           
        },
        error => {          
          console.log(error)
        });
    }
  }

  listCategory(){
    let vm=this;
      this.auth.apiCall('category/list','get',{}, true).subscribe(        
        result => {
          vm.categories = result as any;
          vm.allCategoriesId = vm.categories.map((c) =>  c._id);              
        },
        error => {          
          console.log(error)
        },
        () => {         
        });
  }

  listTopics(){
    let vm=this;
      this.auth.apiCall('forum/topics','post',this.filter, true).subscribe(        
        result => {
          vm.allTopics = result as any;
          vm.distributeTopics(vm.allTopics);
        },
        error => {          
          console.log(error)
        },
        () => {         
        });
  }

  distributeTopics(allTopics : TopicI[]){
    let vm = this;
    vm.favoriteTopics = [];
    vm.topics = [];

    allTopics.forEach((t)=>{
      if(!this.firstIt){
        this.firstTopicId = t._id;
        this.firstIt = true;
      }
      if(t.favorites?.some((f) => f.user === vm.currentUser._id)){
        vm.favoriteTopics.push(t);
      }else{
        vm.topics.push(t);
      }
      if(t.in_news){
        vm.inNewsTopic = t;
      }
    })
  }

  pushOrPullFavorite(id: string){
    let vm=this;
    this.auth.apiCall('forum/topic/favorite/'+id,'get',{}, true).subscribe(        
      result => {
        vm.updateTopicList(id, result as any);         
      },
      error => {          
        console.log('error',error)
      },
      () => {         
      });
  }

  getCurrentUser(){
  let vm=this;
  this.auth.apiCall('user/current-user','get',{}, true).subscribe(        
    result => {
      const res = result as any;
      if(res._id){
        vm.auth.setCurrentUser(res);
        vm.currentUser = res;        
        vm.listTopics();
        if(!res.tutorial && this.auth.getOnBoardingStep()>0){
          setTimeout(()=>vm.initIntroJs(), 700);
        }
      }                      
    },
    error => {          
      console.log('Error',error)
    });
  }

  initIntroJs(): void {
    introJs()
      .setOptions({
        tooltipClass: 'customTooltip',
        highlightClass: 'customHighlight',
        exitOnOverlayClick: false,
        disableInteraction: false,
        showBullets: false,
        steps: [          
          {
            element: document.getElementById('step4'),
            title:  (this.translate.currentLang === 'en') ? "The forum" : 'Le forum',
            intro:  (this.translate.currentLang === 'en') ? 
                    "You have arrived at the Forum section. Here, find the list of all current topics. Use filters to choose specific categories and view related topics."  
                    : 'Vous êtes arrivé sur la partie Forum. Ici, retrouvez la liste de tous les sujets en cours. Utilisez les filtres pour choisir des catégories spécifiques et afficher les sujets associés.',
            position: 'right'
          },
          {
            element: document.getElementById('step5'),
            title: (this.translate.currentLang === 'en') ? "Featured topic" : 'Sujet à la une',
            intro: (this.translate.currentLang === 'en') ? 
              "Use the button to access the topic creation part." 
              : 'Utilisez le bouton pour accéder à la partie création de sujet.',
            position: 'bottom'
          },
          {
            element: document.getElementById('step6'),
            title: (this.translate.currentLang === 'en') ? "New Topic" : 'Nouveau sujet',
            intro: (this.translate.currentLang === 'en') ? "Click here to suggest an idea!" : 'Cliquez ici pour proposer une idée !',
            position: 'top'
          }
        ]
      })
      .start();
      this.cutomizingTooltip();
      this.handlBtn()
  }

  cutomizingTooltip(){
    let vm = this;
    $(".introjs-skipbutton").text((vm.currentUser.language === 'fr') ? 'Passer le tutoriel': 'Skip the tutorial')
    $( ".introjs-nextbutton" ).html('<i class="bi bi-chevron-right"></i>');
    $( ".introjs-prevbutton" ).html( '<i class="bi bi-chevron-left"></i>');    
    $( ".introjs-prevbutton" ).addClass('hide-btn');
    $('.introjs-button').attr('data-index', 3)
    $( "<span class='counter'>3/8</span>" ).insertAfter( ".introjs-prevbutton" );
  }

  handlBtn(){
    $('.introjs-nextbutton').click((e)=>{      
      this.auth.setOnBoardingStepNext();
      const i = $('.introjs-button').attr('data-index');
      $( ".introjs-nextbutton" ).html('<i class="bi bi-chevron-right"></i>');
      if(i){
        $('.introjs-button').attr('data-index', parseInt(i)+1);       
        $( ".counter" ).html((parseInt(i)+1)+'/8');
        if(i === '3' || i === '4'){
          $(".introjs-helperLayer").addClass("top-z-index");                    
        }
      }else{
        if(this.firstTopicId){   
          this._router.navigate(['/topic',this.firstTopicId]);
        }
      }
    });  

    $('.introjs-prevbutton').click(()=>{      
      this.auth.setOnBoardingStepPrev();
      const i = $('.introjs-button').attr('data-index');
      $( ".introjs-nextbutton" ).html('<i class="bi bi-chevron-right"></i>');
      if(i){
        $('.introjs-button').attr('data-index', parseInt(i)-1);
        $( ".counter" ).html((parseInt(i)-1)+'/8');
        if(i==='4'){             
          $( ".introjs-prevbutton" ).addClass('hide-btn');
        }
        if(i==='5'){
          $(".introjs-helperLayer").addClass("top-z-index");      
        }  
             
      }
    });

    $('.introjs-skipbutton').click(()=>{
      this.auth.affectedTutorial();
    });
  }
}
