<div class="messaging">
    <div class="cont" *ngIf="topic" id="step7">
        <div class="channels" (click)="closeAllEmojiBloc()">
            <div class="title">
                <span>{{"channels" | translate}}</span>
            </div>
            <ul class="channel-list">
                <li *ngFor="let channel of topic.channels" [ngClass]="{'active': channel._id === activeChannel}" (click)=" changeChannel(channel._id)">
                    <img src="assets/images/chan.png">
                    <span>{{channel.name | translate}}</span>
                </li>
            </ul>
            <div class="channels-in-mobile">
                <div class="selected-channel">
                    <img src="assets/images/chan.png">
                    <span>{{selectedCh}}</span>
                </div>
                <select (change)="changeSelectChannel($event)">
                    <option value=""  [selected]='resetChannel'>Liste canaux</option>
                    <option *ngFor="let channel of topic.channels" value="{{channel._id}}">{{channel.name}}</option>
                </select>
            </div>
        </div>       
        <div class="messages">
            <div class="block-msg">
                <div class="msg-head" (click)="closeAllEmojiBloc()">
                    <div class="search-bloc">
                        <div class="search">
                            <span class="material-symbols-outlined">search</span>
                            <input class="search" type="text" placeholder="{{'search' | translate}}" [(ngModel)]="searchTxt" (input)="onSearchChange($event)">                 
                        </div>
                    </div>
                    <!--<div class="not-seen-msg">
                        <span>20+ messages depuis le 21/11/2023</span>
                        <span class="material-symbols-outlined close">cancel</span>
                    </div>-->
                    <div class="description-bloc" *ngIf="channelDescShow">
                        <div class="description-chanal">
                            <div class="logo">
                                <img src="assets/images/pinned.png">
                            </div>
                            <div class="description">
                                <p>{{channelDesc | translate}}</p>
                            </div>
                            <div class="close-bloc">
                                <i class="bi bi-x-circle" (click)="channelDescShow = false"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="msg-list" id="msgs-scroll" [ngClass]="{ 'pass-to-top': !channelDescShow }">                    
                    <div class="message" *ngFor="let message of messages; index as i;">
                        <div class="img-prof" (click)="closeAllEmojiBloc()">
                            <img src="assets/images/avatar.png" *ngIf="users[message.user].picture">
                            <span  class="default-pic" *ngIf="!users[message.user].picture" [style.background]="users[message.user].color">{{getAb(users[message.user].first_name, users[message.user].last_name)}}</span>
                        </div>
                        <div class="msg-detail">
                            <div class="info-action" (click)="closeAllEmojiBloc()">
                                <div class="name-date">
                                    <span class="name">{{users[message.user].first_name + " " + users[message.user].last_name}}</span>
                                    <span class="date" *ngIf="message.createdAt">{{showDateCreatedAt(message.createdAt)}}</span>
                                </div>
                                <div class="msg-action" *ngIf="currentUser">
                                    <span class="dropdown">                                    
                                        <span id="dropdownReaction" class="material-symbols-outlined" data-bs-toggle="dropdown" aria-expanded="false" (click)="postCommentInteraction(message._id, message.interactions)">sentiment_satisfied</span>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownReaction">
                                            <li>
                                                <span class="reaction-item" id="{{message._id}}-like" (click)="sendInteraction('like', message._id)" name="J'aime">👍</span>
                                                <span class="reaction-item" id="{{message._id}}-love" (click)="sendInteraction('love', message._id)" name="J'adore">❤️</span>
                                                <span class="reaction-item" id="{{message._id}}-happy" (click)="sendInteraction('happy', message._id)" name="Heureux">😁</span>
                                                <span class="reaction-item" id="{{message._id}}-idea" (click)="sendInteraction('idea', message._id)" name="Illumination">💡</span>
                                                <span class="reaction-item" id="{{message._id}}-surprised" (click)="sendInteraction('surprised', message._id)" name="Étonné">😲</span>
                                                <span class="reaction-item" id="{{message._id}}-sad" (click)="sendInteraction('sad', message._id)" name="Triste">😢</span>
                                            </li>
                                        </ul>
                                    </span>
                                    <!--<span class="comment-count" id="btn-show-comments" (click)="showComment(message._id)" *ngIf="!message.comments || !message.comments.length">
                                        <span *ngIf="message.comments && message.comments.length">{{message.comments.length}}</span> <span class="material-symbols-outlined com-icon">comment</span>
                                    </span>-->
                                    <span class="dropdown more-actions"  *ngIf="message.user === currentUser._id">                       
                                        <i class="bi bi-three-dots-vertical"  id="dropdownMenuButtonAction" data-bs-toggle="dropdown"></i>
                                        <ul class="dropdown-menu dropdown-menu-end" role="menu" aria-labelledby="dropdownMenuButtonAction">
                                            <li class="dropdown-item" data-bs-toggle="modal" data-bs-target="#updateModal" (click)="SelectUpdateObj('message', message._id, message.text)" >Modifer</li>
                                            <li class="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteModal" (click)="SelectDeleteObj('message',message._id)" >Supprimer</li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                            <div class="txt">                                
                                <textarea style="width: 100%;
                                display: inline-table;
                                border: none; background: none;" readonly (click)="closeAllEmojiBloc()">{{message.text}}</textarea>                                
                            </div>
                            <div class="action" (click)="closeAllEmojiBloc()">
                                <ul class="reactions">
                                    <ng-container *ngFor="let interaction of getInteractionCount(message.interactions) | keyvalue">
                                        <li *ngIf="rectionValue(interaction.value,'count') > 0">{{rectionValue(interaction.value,'icon')}}<span class="number">{{rectionValue(interaction.value,'count')}}</span></li>
                                    </ng-container>
                                </ul>                                
                            </div>
                            <div class="reply-count" *ngIf="message.comments && message.comments.length" (click)="showComment(message._id)">{{message.comments.length}} {{"answers" | translate}}</div>
                            <div class="reply-count reply-btn" id="btn-show-comments" (click)="showComment(message._id)" *ngIf="!message.comments || !message.comments.length">{{"reply" | translate}}</div>

                            <div class="comments hide" id="comments-{{message._id}}">
                                <div class="message" *ngFor="let comment of message.comments">
                                    <div class="img-prof" (click)="closeAllEmojiBloc()">
                                        <img src="assets/images/avatar.png" *ngIf="users[message.user].picture">
                                        <span  class="default-pic" *ngIf="!users[comment.user].picture" [style.background]="users[comment.user].color">{{getAb(users[comment.user].first_name, users[comment.user].last_name)}}</span>
                                    </div>
                                    <div class="msg-detail" (click)="closeAllEmojiBloc()">
                                        <div class="info-action">
                                            <div class="name-date">
                                                <span class="name">{{users[comment.user].first_name}}</span>
                                                <span class="date"*ngIf="comment.createdAt">{{showDateCreatedAt(comment.createdAt)}}</span>
                                            </div>
                                            <div class="msg-action" *ngIf="currentUser._id">
                                                <span class="dropdown">                                    
                                                    <span class="material-symbols-outlined" data-bs-toggle="dropdown" aria-expanded="false" (click)="postCommentInteraction(comment._id, comment.interactions)">sentiment_satisfied</span>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <span class="reaction-item" id="{{comment._id}}-like" (click)="sendInteraction('like', message._id, comment._id)" name="J'aime">👍</span>
                                                            <span class="reaction-item" id="{{comment._id}}-love" (click)="sendInteraction('love', message._id, comment._id)" name="J'adore">❤️</span>
                                                            <span class="reaction-item" id="{{comment._id}}-happy" (click)="sendInteraction('happy', message._id, comment._id)" name="Heureux">😁</span>
                                                            <span class="reaction-item" id="{{comment._id}}-idea" (click)="sendInteraction('idea', message._id, comment._id)" name="Illumination">💡</span>
                                                            <span class="reaction-item" id="{{comment._id}}-surprised" (click)="sendInteraction('surprised', message._id, comment._id)" name="Étonné">😲</span>
                                                            <span class="reaction-item" id="{{comment._id}}-sad" (click)="sendInteraction('sad', message._id, comment._id)" name="Triste">😢</span>
                                                        </li>
                                                    </ul>
                                                </span>
                                                <span class="dropdown more-actions"  *ngIf="comment.user === currentUser._id">                       
                                                    <i class="bi bi-three-dots-vertical"  id="dropdownMenuButtonAction" data-bs-toggle="dropdown"></i>
                                                    <ul class="dropdown-menu dropdown-menu-end" role="menu" aria-labelledby="dropdownMenuButtonAction">
                                                        <li class="dropdown-item" data-bs-toggle="modal" data-bs-target="#updateModal" (click)="SelectUpdateObj('comment', message._id, comment.text, comment._id)" >Modifer</li>
                                                        <li class="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteModal" (click)="SelectDeleteObj('comment',message._id,comment._id)" >Supprimer</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="txt">
                                            <textarea style="width: 100%;
                                                display: inline-table;
                                                border: none; background: none;" readonly (click)="closeAllEmojiBloc()">{{comment.text}}</textarea>
                                                                                           
                                        </div>
                                        <div class="action" (click)="closeAllEmojiBloc()">
                                            <ul class="reactions">
                                                <ng-container *ngFor="let interaction of getInteractionCount(comment.interactions) | keyvalue">
                                                    <li *ngIf="rectionValue(interaction.value,'count') > 0">{{rectionValue(interaction.value,'icon')}}<span class="number">{{rectionValue(interaction.value,'count')}}</span></li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="comment-input">                                                                                                    
                                    <div class="message-bloc">                                        
                                        <textarea matInput
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="1"
                                        cdkAutosizeMaxRows="2"
                                        placeholder="{{'yourcmt' | translate}}"
                                        id="comment-input-{{message._id}}"
                                        (keyup.enter)="sendComment(message._id)"
                                        class="areatext" (click)="closeAllEmojiBloc()"></textarea>
                                        <i class="bi bi-emoji-smile" (click)="openEmoji(false, i, message.emoji)"></i>
                                        <emoji-mart *ngIf="message.emoji" [style]="{ position: 'absolute', top: '50px', right: '20px' }"
                                            [showPreview]="false"
                                            [i18n]="{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Récents' } }"
                                            (emojiClick)="addEmoji($event, 'comment-input-'+message._id)" id="comment-emoji-{{message._id}}">
                                        </emoji-mart> 
                                    </div>
                                    <button class="send-msg" (click)="sendComment(message._id)">
                                        <span class="material-symbols-outlined ready-to-send">send</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="msg-bottom"></div>                                
                </div>
                <div class="message-input" *ngIf="is_ok_to_vote">

                    <div class="message-bloc">                        
                        <textarea matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="2"
                        (keyup.enter)="sendMessage()"
                        placeholder="{{'yourMsg' | translate}}" id="message-input-val" (click)="closeAllEmojiBloc()"></textarea>
                        <i class="bi bi-emoji-smile" (click)="openEmoji(true, -1, showEmojiBloc)"></i>
                        <emoji-mart *ngIf="showEmojiBloc" [style]="{ position: 'absolute', bottom: '50px', right: '20px' }"
                            [showPreview]="false"
                            [i18n]="{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Récents' } }"
                            (emojiClick)="addEmoji($event, 'message-input-val')" class="emoji-bloc">
                        </emoji-mart>                       
                    </div>
                    <button class="send-msg" (click)="sendMessage()">
                        <span class="material-symbols-outlined ready-to-send">send</span>
                    </button>

                </div>
                <div class="suggestion-list">
                    <ul>
                        <li *ngFor="let sugUser of suggestionList">
                            <div class="img-prof">
                                <img src="assets/images/avatar.png" *ngIf="sugUser.picture">
                                <span  class="default-pic" *ngIf="!sugUser.picture" [style.background]="sugUser.color">{{getAb(sugUser.first_name, sugUser.last_name)}}</span>
                            </div>
                            <div class="name-bloc">
                                <span>{{sugUser.first_name+' '+sugUser.last_name}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal delete message or comment  -->
<div class="modal fade delete-update-modal" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header-mod">
          <p class="modal-title" id="exampleModalLabel" *ngIf="delUpObjectType ==='comment'">{{"deleteCmt" | translate}} ?</p>
          <p class="modal-title" id="exampleModalLabel" *ngIf="delUpObjectType !=='comment'">{{"deleteMsg" | translate}} ?</p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body-mod">
          <p *ngIf="delUpObjectType ==='comment'">{{"deleteTxt" | translate}} {{"comment"}} ?</p>
          <p *ngIf="delUpObjectType !=='comment'">{{"deleteTxt" | translate}} {{"message"}} ?</p>
        </div>
        <div class="modal-footer-mod">
          <button type="button" class="btn btn-delete" data-bs-dismiss="modal" (click)="confirmDelete()">{{"delete" | translate}}</button>
        </div>
      </div>
    </div>
  </div>

<!-- Modal update message or comment  -->
<div class="modal fade delete-update-modal" id="updateModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header-mod">
          <p class="modal-title" id="exampleModalLabel" *ngIf="delUpObjectType ==='comment'">{{"editCmt" | translate}} ?</p>
          <p class="modal-title" id="exampleModalLabel" *ngIf="delUpObjectType !=='comment'">{{"editMsg" | translate}} ?</p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body-mod">
            <textarea matInput
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="1"
                                        cdkAutosizeMaxRows="2" [(ngModel)]="textToUpdate" id="textarea-for-update"></textarea>
        </div>
        <div class="modal-footer-mod">
          <button type="button" class="btn btn-update" data-bs-dismiss="modal" (click)="confirmUpdate()">{{"update" | translate}}</button>
        </div>
      </div>
    </div>
</div>
