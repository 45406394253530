import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { io } from "socket.io-client";

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  SOCKET_PREFIX = environment.socketEndPoint;
  public socket : any;
  constructor() {
    this.socket = io(this.SOCKET_PREFIX);
    this.socket.on("connect", () => {
      console.log('connect');            
    });    
  }
  
}
