<div class="topic-detail" *ngIf="topic">
    <div class="owner-vote">
        <div class="owner-name">
           <span>{{"proposerBy" | translate}} @{{topic.user.first_name+"_"+topic.user.last_name}}</span>
        </div>
        <div class="owner" *ngIf="remainingDays">
            <span class="material-symbols-outlined clock">alarm</span>
            <span class="numbers">{{remainingDays}} {{"remainDays" | translate}}</span>
        </div>
		<div>
			<span class="">{{nbr_votant}} votant(s)</span>
		</div>
		</div>
    <div class="topic-category">        
        <span>{{topic.category.name | translate}}</span>
        <button *ngIf="currentUser && currentUser._id === topic.user._id" [routerLink]="['/edit-topic',topic._id]">{{"editTopic" | translate}}</button>
    </div>
    <div class="description" [ngClass]="{'more-height': !resultDisplay}" id="desc-content">
            <textarea id="desc-bloc" [ngClass]="{'more-height': !resultDisplay}"
           [(ngModel)]="topic.description" readonly></textarea>
           <div class="scroll-down" *ngIf="isScroll">
            <span class="material-symbols-outlined">expand_more</span>
        </div>        
        <app-vote-result [topic]="topic" [election]="election" *ngIf="topic && election && resultDisplay"></app-vote-result>
    </div>
       
</div>