import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';
import { UserI, PostI, TopicI, InteractionI, CommentI, AuthUser, TrainingI, ElectionI, VoteI } from '../models';
import { AuthService } from '../services/auth.service';
import { SocketService } from '../services/socket.service';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ChatComponent implements OnChanges{
  @Input() topic: TopicI;
  @Input() election: ElectionI;
  constructor(private auth: AuthService, private chat : SocketService, 
    private _adapter: DateAdapter<any>,
	private route: ActivatedRoute,
    private _intl: MatDatepickerIntl,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private translate: TranslateService) {

		interface JwtPayload {
			exp: number,
			firstName: string,
			iat: number,
			lastName: string,
			role: string,
			sub: string,
			username: string,
			wallet: string
		}
	
		const id_sujet_assemblage = "Répartition des augmentations individuelles - MU Assemblage"
		// const id_sujet_assemblage = "animal"
		const assemblage = ["r.bliezc@nicomatic.ch", "j.albouc@nicomatic.fr", "a.allouch@nicomatic.fr", "r.bakalem@nicomatic.fr", "c.barbier@nicomatic.fr", "e.bercasio@nicomatic.fr", "o.bertin@nicomatic.fr", "j.careme@nicomatic.fr", "ml.cart@nicomatic.fr", "f.chourfi@nicomatic.fr", "d.couton@nicomatic.fr", "a.delerce@nicomatic.fr", "m.dubois@nicomatic.fr", "i.ferdinand@nicomatic.fr", "s.gaulet@nicomatic.fr", "a.girod@nicomatic.fr", "n.gueguen@nicomatic.fr", "j.guilhot@nicomatic.fr", "d.hatton@nicomatic.fr", "a.hottier@nicomatic.fr", "b.jacquier@nicomatic.fr", "v.jacquier@nicomatic.fr", "m.josse@nicomatic.fr", "mt.lamoussiere@nicomatic.fr", "a.laout@nicomatic.fr", "m.lausenaz@nicomatic.fr", "f.lus@nicomatic.fr", "a.martina@nicomatic.fr", "a.niermarechal@nicomatic.fr", "s.olivo@nicomatic.fr", "j.racine@nicomatic.fr", "d.rochereuil@nicomatic.fr", "v.rochette@nicomatic.fr", "f.rognard@nicomatic.fr", "e.sahin@nicomatic.fr", "l.salvi@nicomatic.fr", "m.vesin@nicomatic.fr"];
		const token = localStorage.getItem('access_token') as any;
		const decoded : JwtPayload = jwtDecode(token);
		var id_vote;
		var name_topic = "";
		this.route.params.subscribe(params => {
			id_vote = params['id'];
			console.log(id_vote, id_sujet_assemblage)
			// const name_topic = apicall get topic name by id
		  });
		this.auth.apiCall('forum/topic/'+id_vote,'get',{}, true).subscribe(        
			result => {
				let test : any;
				test = result;
				name_topic = test.title;
				console.log("name: ", name_topic)
				// console.log("yoyoyoyoyo", assemblage.indexOf(decoded.username), decoded.username)
				if (id_sujet_assemblage != name_topic)
				{
					// console.log("PAS meme nom donc true");
					this.is_ok_to_vote = true;
				}
				// if (name_topic == id_vote && assemblage.indexOf(decoded.username) != -1)
				if (id_sujet_assemblage == name_topic && assemblage.indexOf(decoded.username) != -1)
				{
					// console.log("meme nom + dans la liste donc true");
					this.is_ok_to_vote = true;
				}

			},
			error => {          
				console.log('error',error)
			},
			() => {         
		});
	}

  delUpObjectType: string = "";
  delUpMessageId: string = "";
  delUpCommentId: string = "";
  searchTxt: string = "";
  updateObjectType: string;
  allMessages: PostI[];
  messages : PostI[];
  users: any;
  textToUpdate: string = "";
  currentUser: AuthUser;
  activeChannel: string;
  resetChannel: boolean = true
  channelDesc: string;
  channelDescShow: boolean = true;
  selectedCh: string; 
  suggestionList: AuthUser[];
  postShowTagList: boolean = false;
  commentShowTagList: boolean = false;
  handledChannelList: string[] = [];
  showEmojiBloc: boolean = false;
  showAllEmojiBlocs: boolean = false;
  messageIndexEmoji: number;
  isEmojiMsgOpen: boolean;
  is_ok_to_vote: boolean = false;

  changeSelectChannel($event: any){
    this.resetChannel = false;
    let vm = this;
    if($event.target?.value.length){  
      this.selectedCh = this.getChannelName($event.target?.value);
      this.getTopic($event.target?.value);
      vm.resetChannel = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['topic']?.currentValue?.channels[0]){
      this.activeChannel = changes['topic']?.currentValue?.channels[0]?._id;
      this.selectedCh = changes['topic']?.currentValue?.channels[0]?.name;      
      this.channelDesc = changes['topic']?.currentValue?.channels[0]?.description;    
    }
    if(this.activeChannel){
      this.getTopic(this.activeChannel);
    }    
  }

  openEmoji(isForMsg: boolean, index: number, currentValue: boolean| undefined){ 
    if(!currentValue) { 
        if(isForMsg){
          this.isEmojiMsgOpen = true;
          this.showEmojiBloc= !this.showEmojiBloc
        }else{
          this.isEmojiMsgOpen = false;
          this.messageIndexEmoji = index;
          this.messages[index].emoji = !this.messages[index].emoji;      
        }
    }else{
      this.closeAllEmojiBloc();
    }
  }

  // checklanguagebeforDisplay(){
  //   if(this.selectedCh === )
  //   return 
  // }
  
  closeAllEmojiBloc(){
    if(this.showEmojiBloc){
      this.showEmojiBloc = false;
    }
    if(!this.isEmojiMsgOpen && this.messageIndexEmoji >=0){
      this.messages[this.messageIndexEmoji].emoji = false;
    }
  }

  ngOnInit():void{
    this._locale = 'fr';
    this._adapter.setLocale(this._locale);
    this.getUsers();
    if(this.auth.currentUser()){
      this.currentUser = this.auth.currentUser();
    }else{
      this.getCurrentUser();
    }
  }

  SelectDeleteObj(type: string, messageId:string, commentId?: string){
    this.delUpObjectType = type;
    this.delUpMessageId = messageId;
    if(commentId){
      this.delUpCommentId = commentId;
    }    
  }

  addEmoji(event: any, index: string){
    const txt = $("#"+index).val();
    $("#"+index).val(txt+event.emoji.native);
  }

  SelectUpdateObj(type: string, messageId:string, txtToUpdate: string, commentId?: string){
    this.delUpObjectType = type;
    this.delUpMessageId = messageId;
    this.textToUpdate = txtToUpdate;    
    var lines = this.textToUpdate.split("\n"); 
    if(lines.length>2){
      $('#textarea-for-update').height(lines.length*26)
    }
    if(commentId){
      this.delUpCommentId = commentId;
    }    
  }

  getCurrentUser(){
    let vm=this;
    this.auth.apiCall('user/current-user','get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          this.auth.setCurrentUser(res);
          this.currentUser = res
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }

  onSearchChange(searchValue: any): void { 
    let vm = this;
    if(this.searchTxt.length){
      this.messages = this.allMessages.filter((m)=>m.text.includes(vm.searchTxt));
    }else{
      this.messages = this.allMessages;
    }
  }

  parseToInt(index: string):number{
    return parseInt(index);
  }  

  getChannelName(id: string): string{
    const channel = this.topic.channels.find(el => el._id === id);
    this.channelDesc = channel?.description || "";
    return (channel) ? channel.name : '';
  }

  postCommentInteraction(id: string, interactions: InteractionI[]){
    const found = interactions.find((i) => i.user === this.auth.currentUser()._id);
    if(found){
      $(".reaction-item").removeClass( "active" );
      $( "#"+id+"-"+found.type).addClass( "active" );
    }
  } 

  changeChannel(channelId: string){
    this.activeChannel = channelId;
    this.getChannelName(channelId)
    this.getTopic(channelId);
  } 

  showComment(commentList: string){
    if($('#comments-'+commentList ).hasClass( "hide" )){
      $('#comments-'+commentList ).removeClass( "hide" );    
      $('#comment-input-'+commentList ).focus();
    }else{
      $('#comments-'+commentList ).addClass( "hide" );
    }
  }
  
  showDateCreatedAt(chDate: string): string{
    const d = new Date(chDate)
    const day = (d.getDate()<10) ? '0'+d.getDate() :  d.getDate();
    const mounth = ((d.getMonth()+1)<10) ? '0'+(d.getMonth()+1) : d.getMonth()+1;
    const hours = (d.getHours()>9) ? d.getHours() : '0'+d.getHours();
    const minutes = (d.getMinutes()>9) ? d.getMinutes() : '0'+d.getMinutes();
    return  [day,
              mounth,
               d.getFullYear()].join('/')+' '+
              [hours,
               minutes].join(':');
  }

  getTopic(id: string){
    let vm=this;
    this.auth.apiCall('forum/channel/'+id,'get',{}, true).subscribe(        
      result => {
        vm.allMessages = result as any;
        vm.messages = vm.allMessages.map((msg)=>{ msg.emoji = false; return msg;} )
        vm.showLastMsg();        
        vm.channelDescShow = true;                 
      },
      error => {          
        console.log('Error',error)
      },
      () => {         
    });
    this.hundelMessage(id);
  }

  deleteMessage(){
    const data = {
      topic: this.topic._id,      
      channel: this.activeChannel,
      _id: this.delUpMessageId
    };
    let vm=this;
    this.auth.apiCall('forum/delete-post','post',data, true).subscribe(        
      result => {
        this.removePost();
        const p = result as any;     
        this.delUpObjectType = "";
        this.delUpMessageId = "";         
      },
      error => {          
        console.log('Error',error)
      },
      () => {         
    });
  }

  
  confirmDelete(){
    if((this.delUpMessageId.length  || this.delUpCommentId) && this.delUpObjectType){
      if(this.delUpObjectType === "comment"){
        this.deleteComment();
      }else{
        this.deleteMessage();
      }
    }
  }

  deleteComment(){
    const data = {
      topic: this.topic._id,      
      channel: this.activeChannel,
      _id: this.delUpCommentId,
      post: this.delUpMessageId
    };
    let vm=this;
    this.auth.apiCall('forum/delete-comment','post',data, true).subscribe(        
      result => {
        const p = result as any;
        this.removePost(); 
        this.delUpObjectType = "";
        this.delUpMessageId = "";
        this.delUpCommentId = "";       
      },
      error => {          
        console.log('Error',error)
      },
      () => {         
    });
  }

  removePost(){
    const messageTodeleteId = this.messages.findIndex((m) => m._id === this.delUpMessageId);
    if(this.delUpObjectType === "comment" && this.delUpCommentId.length){
      const commentTodeleteId = this.messages[messageTodeleteId].comments.findIndex((c) => c._id === this.delUpCommentId);
      this.messages[messageTodeleteId].comments.splice(commentTodeleteId, 1);
    }else{
      this.messages.splice(messageTodeleteId, 1);
    }    
  }

  updatePost(){
    const messageTodeleteId = this.messages.findIndex((m) => m._id === this.delUpMessageId);
    if(this.delUpObjectType === "comment" && this.delUpCommentId.length){
      const commentTodeleteId = this.messages[messageTodeleteId].comments.findIndex((c) => c._id === this.delUpCommentId);
      this.messages[messageTodeleteId].comments[commentTodeleteId].text= this.textToUpdate;
    }else{
      this.messages[messageTodeleteId].text = this.textToUpdate;
    }    
  }

  updateMessage(){
    if(this.textToUpdate.length){
      const data = {
        topic: this.topic._id,      
        channel: this.activeChannel,
        text: this.textToUpdate,
        _id: this.delUpMessageId
      };
      let vm=this;
      this.auth.apiCall('forum/create-update-post','post',data, true).subscribe(        
        result => {
          this.updatePost();
          const p = result as any;     
          vm.delUpObjectType = "";
          vm.delUpMessageId = "";
          vm.textToUpdate = "";       
        },
        error => {          
          console.log('Error',error)
        },
        () => {         
      });
    }
  }

  

  updateComment(){
    if(this.textToUpdate.length){
      const data = {
        topic: this.topic._id,      
        channel: this.activeChannel,
        comment: this.textToUpdate,
        post: this.delUpMessageId,
        _id: this.delUpCommentId
      };
      let vm=this;
      this.auth.apiCall('forum/create-update-comment','post',data, true).subscribe(        
        result => {
          this.updatePost();
          const p = result as any;     
          vm.delUpObjectType = "";
          vm.delUpMessageId = "";
          vm.textToUpdate = "";
          this.delUpCommentId = "";       
        },
        error => {          
          console.log('Error',error)
        },
        () => {         
      });
    }
  }

  confirmUpdate(){
    if((this.delUpMessageId.length  || this.delUpCommentId) && this.delUpObjectType){
      if(this.delUpObjectType === "comment"){
        this.updateComment();
      }else{
        this.updateMessage();
      }
    }
  }

  usersById(users: UserI[]){
    let usersObj: any = [];
    users.forEach((u) => usersObj[u._id]= u);
    return usersObj;
  }
  
  getAb(firstN: string, lastN: string):string{
    return firstN[0]+lastN[0]
  }
  filterargs ={count:0, icon: '👍'}
  getInteractionCount(interactions: InteractionI[]): any{
    let interactionCount = {
          "like": {count:0, icon: '👍'}, "love": {count:0, icon: '❤️'},
          "happy": {count:0, icon: '😁'}, "idea": {count:0, icon: '💡'},
          "surprised": {count:0, icon: '😲'}, "sad":{count:0, icon: '😢'}};
    interactions.forEach((i) => interactionCount[i.type].count++);
    return interactionCount;
  }

  rectionValue(obj: any, react: string){
    return obj[react];
  }

  getUsers(){
    let vm=this;
    this.auth.apiCall('user/list','get',{}, true).subscribe(        
      result => {
        vm.users = this.usersById(result as any);
        vm.suggestionList =  vm.users;
      },
      error => {          
        console.log('Error',error)
      },
      () => {         
      });
  }

  sendMessage(){
    let value = $('#message-input-val').val() as any;
    value = value.replace(/^\s+|\s+$/g, '');
    if(value.length){
      const data = {
        topic: this.topic._id,
        channel: this.activeChannel,
        text: value
      }
      let vm = this;
      this.auth.apiCall('forum/create-update-post','post',data, true).subscribe(        
        result => {
          $('#message-input-val').val("");
        },
        error => {          
          console.log('error',error)
      });
    }
  }

  pushComment(messageId: string, comment: CommentI){
    this.messages.map(m=>{
      if(m._id === messageId){
        m.comments?.push(comment);
      }
      return m;
    })
  }

  pushInteraction(messageId: string, interactions: InteractionI[], commentId?: string){
    this.messages.map(m=>{
      if(m._id === messageId){
        if(commentId){
          m.comments.map(c=>{
            if(c._id === commentId){
              c.interactions = interactions;
            }
            return c;
          })
        }else{
          m.interactions = interactions;
        }
      }
      return m;
    })
  }

  

  sendComment(messageId: string){
    this.closeAllEmojiBloc();
    const value = $('#comment-input-'+messageId).val();
    if(value){
      const data = {
        topic: this.topic._id,
        channel: this.activeChannel,
        text: value,
        post: messageId,
        comment: value
      }
      let vm = this;
      this.auth.apiCall('forum/create-update-comment','post',data, true).subscribe(        
        result => {
          const r : any = result;         
          $('#comment-input-'+messageId).val("");
          if(r._id && r.text){
            vm.pushComment(messageId, r)
          }         
        },
        error => {          
          console.log('error',error)
      });
    }
  }
  
  sendInteraction(type: string, messageId: string, commentId?: string){
      let data = {
        topic: this.topic._id,
        channel: this.activeChannel,
        post: messageId,
        type,
        comment: null      
      }      
      if(commentId){
        data.comment = commentId as any
      }
      let vm = this;
      this.auth.apiCall('forum/interaction-crud','post',data, true).subscribe(        
        result => {
          const r : any = result;
          vm.pushInteraction(messageId, r, commentId);         
        },
        error => {          
          console.log('error',error)
      });
  }

  showLastMsg(){
    $("#msgs-scroll").animate({ scrollTop: $('#msgs-scroll').prop("scrollHeight")+10000}, 100);
  }

  hundelMessage(channelId: string){
    let vm = this;
    if(!this.handledChannelList.includes(channelId)){
      this.handledChannelList.push(channelId)
      this.chat.socket.on(channelId, function(data: any) {
        if(data.post_id){
          const com = data._doc;
          const messageIndex = vm.messages.findIndex((m) => m._id === data.post_id);
          if(messageIndex >= 0 && com.user !== vm.currentUser._id){
            vm.messages[messageIndex].comments.push(com);
          }
        }
        if(data._id){        
            vm.messages.push(data);
            const objDiv = document.getElementById("msgs-scroll");
            if(objDiv){
              setTimeout(()=>objDiv.scrollTop = objDiv.scrollHeight+ 1000, 100)
              objDiv.scrollTop = objDiv.scrollHeight+ 1000;    
            }
        }
      });
    }
  }
}
