import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, tap} from 'rxjs/operators';
import { AuthUser } from '../models';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router:Router) { }
  API_PREFIX = environment.APIEndpoint;

  currentHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  
  authUser : AuthUser;

  onBoardingStep: number = 0;

  firstInterface : boolean = false;

  public lastOnBoarding: boolean = false;

  setFirstInterface(){
    this.firstInterface = true;
  }

  getFirstInterface(){
    return this.firstInterface;
  }

  getLastOnBoarding(){
    return this.lastOnBoarding;
  }

  setLastOnBoarding(val: boolean){
    this.lastOnBoarding = val;
  }

  setOnBoardingStepNext(){
    this.onBoardingStep++;
  }

  setOnBoardingStepPrev(){
    this.onBoardingStep--;
  }

  getOnBoardingStep(){
    return this.onBoardingStep;
  }

  currentUser(): AuthUser{
    return this.authUser;
  }

  setCurrentUser(user: AuthUser){
    this.authUser = user;
  }

  
  calculateDayDifference(dateMin: string, dateMax: string){
    const date1 = new Date(dateMin);
    const date2 = new Date(dateMax);
    
    // Calculating the time difference
    // of two dates
    const difference_In_Time = date2.getTime() - date1.getTime();
    
    // Calculating the no. of days between
    // two dates
    const difference_In_Days = Math.round(difference_In_Time / (1000 * 3600 * 24));
    
    // To display the final no. of days (result)
    return difference_In_Days;
  }
    
  getCurrentUser(){
    let vm=this;
    this.apiCall('user/current-user','get',{}, true).subscribe(        
      result => {
        const res = result as any;
        if(res._id){
          this.setCurrentUser(res);
        }                      
      },
      error => {          
        console.log('Error',error)
      });
  }

  affectedTutorial(){
    let vm=this;
    this.apiCall('user/turorial-affected','get',{}, true).subscribe(        
      result => {
        const res = result as any;
        this.authUser.tutorial = true;                  
      },
      error => {          
        console.log('Error',error)
      });
  }

  getUploadedFile(url:string){
    let headers = new HttpHeaders().append("Authorization", `Bearer ${localStorage.getItem("access_token")}`)
    return this.http.get(this.API_PREFIX +url, {responseType: 'blob',headers:headers});
  }

  getApiBasePath(){
    return this.API_PREFIX;
  }

  public apiCall(url: string, method = 'post', data = {}, auth = false, customHeader = null) {
    let h = this.currentHeader;    
    let ob;
    let vm = this;
    if(auth){
      if(customHeader){
        h = new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        });
      }else{
        h = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        });
      }
    }else if (customHeader && customHeader === true){ 
      h = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    else if (customHeader && typeof (customHeader) == 'object'){
       h = new HttpHeaders(customHeader);
    }
    if (!method || method.toLowerCase() == 'get') {
      let params = new HttpParams(data);      
      ob = this.http.get(this.API_PREFIX + url, { headers: h, params: params });
    } else {
      ob = this.http.post(this.API_PREFIX + url, data, { headers: h });
    }
    return ob.pipe(
      tap((data) => {
        if (!data /*|| !data['status'] || data['status'] != 'success'*/) throw ({ apiError: true, msg: url, res: data });
      }),
      catchError((err) => { 
        if(err.error && err.error?.message === 'Unauthorized' && err.error.statusCode === 401){
          vm.router.navigate(['']); 
        }      
        throw (err);
      })
    );
  }
}
